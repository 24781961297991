.individuals {
  .text {
    padding-bottom: 20px;
  }

  .promo {
    margin-bottom: 80px;
  }

  @media (--tablet-up) {
    .text {
      padding-bottom: 32px;
    }

    .promo {
      margin-bottom: 120px;
    }
  }

  @media (--desktop-up) {
    .promo {
      margin-bottom: 180px;
    }
  }
}

.menu-btn {
  cursor: pointer;
  height: 30px;
  margin-left: -6px;
  width: 30px;
  padding: 14px 6px;
  position: relative;
  z-index: 21;

  i {
    background: currentColor;
    display: block;
    height: 2px;
    width: 18px;
    transition: all .5s ease;
  }

  &::before,
  &::after {
    background: currentColor;
    content: '';
    position: absolute;
    height: 2px;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
    width: 18px;
    transition: all .5s ease;
    transform-origin: 50% 50%;
  }

  &::before {
    transform: translateY(-5px);
  }

  &::after {
    transform: translateY(5px);
  }

  &--desktop {
    margin-left: 15px;
  }

  @media (--tablet-down) {
    margin-right: 26px;
  }
}

.menu-shown {
  .menu-btn {
    i {
      opacity: 0;
      transform: scaleX(0);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }
}

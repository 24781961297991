.features {
  padding: 20px 0 0;
  position: relative;

  .title {
    margin-bottom: 18px;
  }

  .feature {
    align-items: center;
    display: flex;
    padding: 20px 0;
    position: relative;

    &__image {
      background: #000;
      position: relative;
      max-width: 825px;

      &::after {
        content: '';
        display: block;
        padding-bottom: 60%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        object-fit: cover;
      }
    }
  }

  @media (--mobile) {
    .feature {
      padding: 40px 0;

      &:not(:last-child) {
        padding-bottom: 0;
      }

      &__image {
        max-width: 400px;
        margin: 0 auto 20px;
      }

      &__info {
        margin-bottom: 20px;
      }
    }
  }

  @media (--tablet-down) {
    .feature {
      flex-direction: column;
      text-align: center;

      > div {
        max-width: 500px;
        width: 100%;
      }
    }
  }

  @media (--tablet-up) {
    padding: 40px 0 0;

    .feature {


      &__image {

      }



    }
  }

  @media (--laptop-up) {
    .feature {
      align-items: center;
      display: flex;
      padding: 60px 0;

      &__image {
        width: 66.66%;
        flex-shrink: 0;
      }

      &__info {
        flex-grow: 1;

         > div {
           max-width: 310px;
           margin: 0 auto;
         }
      }

      &--text-right {
        .feature__image {
          order: -1;
        }

        .feature__info {
          padding-left: 30px;
        }
      }
    }
  }

  @media (--desktop-up) {

    padding: 70px 0 0;

    &__drawing {
      background: no-repeat 100% 50% / contain;
      position: absolute;
      right: 0;
      top: 36%;
      height: 606px;
      width: 412px;
    }

    .feature {
      padding: 90px 0;
    }
  }
}

.footer-logo {
    height: 25px;
    vertical-align: middle;
    margin-bottom: 4px;
    padding-right: 10px;
}
@media (--mobile) {
  .footer-logo {
      height: 50px;
      margin-bottom: 10px;
      vertical-align: unset;
      padding-right: 0px;
  }
  .footer__container { text-align: center; }
}

.footer {
  background: #141414;
  color: #cebebe;

  .column {
    &__title {
      color: #cab49b;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    li {
      letter-spacing: 0;
      padding-bottom: 11px;
      text-transform: capitalize;
    }
  }

  &__bottom {
    color: #b9abab;
    display: flex;
    font-weight: 300;
    line-height: 1.6em;
    letter-spacing: 0;
  }

  a {
    transition: all .1s ease;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }

  @media (--mobile) {
    font-size: 16px;
    padding: 38px 0 24px;

    &__columns {
      padding: 0 10px 24px;
    }

    .column {
      &:not(.column--payments) {
        border-bottom: 1px solid #2c2a2b;

        .column__title {
          font-size: 18px;
          line-height: 2;
          margin: 0 -15px;
          padding: 4px 15px 2px;
          position: relative;
          cursor: pointer;

          i {
            align-items: center;
            right: 12px;
            top: calc(50% - 10px);
            height: 20px;
            width: 20px;
            transition: all .2s ease;
            position: absolute;

            &::before,
            &::after {
              background: currentColor;
              content: '';
              width: 11px;
              height: 1px;
              position: absolute;
              left: 5px;
              top: 10px;
            }

            &::after {
              transform: rotate(90deg);
              transition: all .2s ease;
            }
          }
        }

        ul {
          visibility: hidden;
          max-height: 0;
          opacity: 0;
          transition: max-height .2s .1s ease, opacity .2s 0s ease, visibility 0s .4s;
        }
      }

      &--payments {
        padding-top: 64px;

        .column__title {
          display: none;
        }
      }

      &.expanded {
        ul {
          visibility: visible;
          max-height: 500px;
          opacity: 1;
          transition: max-height .2s 0s ease, opacity .2s .1s ease, visibility 0s 0s;
        }

        .column__title {
          i {
            &::after {
              opacity: 0;
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .divider {
      display: none;
    }

    &__bottom {
      flex-direction: column;
      align-items: center;

      span {
        display: block;
        text-align: center;
      }

      a {
        order: -1;
        margin-bottom: 20px;
      }
    }
  }

  @media (--tablet-up) {
    padding: 92px 0 38px;

    &__columns {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 50px;
    }

    .column {
      flex-basis: 160px;
      flex-grow: 1;
      max-width: 190px;
      margin-bottom: 28px;

      &__title {
        margin-bottom: 12px;
      }

      &:not(.column--payments) {
        padding-right: 30px;
      }

      &--payments {
        max-width: 160px;

        .column__title {
          margin-bottom: 9px;
        }
      }
    }

    &__bottom {
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (--tablet) {
    padding-left: 20px;
    padding-right: 20px;

    .column--payments {
      max-width: 150px;
    }
  }

  @media (--desktop-up) {
    &__columns {
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 100px;
    }
  }
}


.box {
  margin-bottom: 50px;
  padding: 30px 0;

  &__title {
    margin-bottom: 50px;
    text-align: center;
  }

  .item {
    &__image {
      background: #cab49b;
      position: relative;

      &::after {
        content: '';
        display: block;
        padding-bottom: 78%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__title {
      font-family: 'Neutra Text TF', sans-serif;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: .1em;
      margin-bottom: 6px;
      text-transform: uppercase;
    }
  }

  &__bottom {
    text-align: center;
    margin-top: 50px;

    .btn {
      width: 350px;
      margin-top: 15px;
      max-width: 100%;
    }
  }

  @media(--mobile) {
    .item {
      align-items: center;
      display: flex;
      margin: 0 auto 20px;
      max-width: 500px;

      &__image {
       flex-shrink: 0;
       width: 40%;
        margin-right: 18px;
      }
    }
  }

  @media (--tablet-up) {
    margin-bottom: 70px;

    &__items {
      display: flex;
      //flex-wrap: wrap;
      margin: 0 -15px;
    }

    .item {
      flex-grow: 1;
      min-width: calc(25% - 30px);
      max-width: calc(33.33% - 30px);
      margin: 0 15px;

      &__image {
        margin-bottom: 28px;
      }
    }
  }

  @media (--desktop-up) {
    margin-bottom: 110px;

    &__bottom {
      margin-top: 90px;
    }
  }
}

.manual {
  padding-bottom: 20px;

  .item {
    &__image {
      background: #dadada;
      position: relative;
      padding-bottom: 66%;
      margin-bottom: 34px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__title {
      font-size: 28px;
      font-weight: 300;
      letter-spacing: 0;
      margin-bottom: 9px;
    }
  }

  @media (--mobile) {
    .item {
      max-width: 540px;
      margin: 0 auto 50px;
    }
  }

  @media (--tablet-up) {
    padding-bottom: 40px;

    &__inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    .item {
      width: calc(50% - 30px);
      margin: 0 15px 50px;
    }
  }

  @media (--desktop-up) {

    .item {
      margin-bottom: 60px;
    }
  }
}

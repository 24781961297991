.link {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 0.1em;
  position: relative;
  text-transform: uppercase;

  &::after {
    background: #cab49b;
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    transform-origin: 50% 50%;
    transition: all .4s ease;
  }

  &:hover {
    text-decoration: none;

    &::after {
      transform: scaleX(1.05) translateY(2px);
    }
  }
}

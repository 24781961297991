html {
  touch-action: manipulation;
  overflow: hidden;
  overflow-y: scroll;
}

body {
  background: #181717;
  color: #1c1c1c;
  font: 400 16px "Neutra Text", sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

input,
button {
  font: inherit;
  letter-spacing: inherit;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
}

::selection {
  background: #302e2f;
  color: #fff;
}

#menu-toggler {
  display: none;
}

.wrapper {
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &.noscroll {
    height: 100vh;
    overflow: hidden;
  }

  &.menu-shown {
    .main__inner {
      transform: scale(.7) translate3d(0,0,0);
      transition: transform .3s cubic-bezier(.975,.015,.46,.99);
    }
  }

  &:not(.loaded) {
    .cart,
    .cart-overlay,
    .menu,
    .drop,
    .lang__menu{
      display: none;
    }

    .watch-details__body {
      opacity: 0;
    }
  }
}

.noise {
  background: url('../images/noise.png') repeat 0 0 / 100px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .02;
  mix-blend-mode: normal;
  pointer-events: none;
  z-index: 1;

  @media (max-device-pixel-ratio: 1.5) {
    background-size: 200px;
  }
}

.no-transition {
  &,
  * {
    transition: none !important;
  }
}




.collection {
  color: #fff;
  overflow: hidden;
  position: relative;

  .center {
    position: relative;
  }

  &__inner {
    position: relative;
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 20px 190px;
    position: relative;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
    opacity: .8;
  }

  .title, p {
    max-width: 500px;
  }

  &__bg {
    background: #444;
    position: absolute;
    height: 100%;
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--story {
    background: transparent;

    .collection__bg {
      display: none;
    }
  }

  @media (--mobile) {
    &__content {
      min-height: 136vw;
    }

    .title {
      font-size: 32px;
    }

    .line {
      margin-bottom: 30px;
    }

    .btn {
      text-transform: capitalize;
    }

    p {
      margin-bottom: 24px;
      max-width: 260px;
    }
  }

  @media (--tablet-up) {
    &__content {
      padding: 40px 40px 190px;
    }

    .line {
      margin-bottom: 36px;
    }
  }

  @media (--laptop-up) {
    &__content {
      padding-bottom: 200px;
    }
  }

  @media (--desktop-up) {
    &__content {
      padding: 60px 88px 262px;
      min-height: 568px;
    }
  }

  @media (--hd) {
    &__content {
      min-height: 700px;
      justify-content: center;
    }
  }
}

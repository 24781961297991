.product-images {
  background: #fff;
  overflow: hidden;
  position: relative;

  &__slider {
    margin: 0 -2px;

    &:not(.slick-initialized) {
      .product-images__slide {
        &:nth-child(n+2) {
          display: none;
        }
      }
    }
  }

  &__slide {
    position: relative;
    margin: 0 2px;

    &::after {
      content: '';
      display: block;
      padding-bottom: 130%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4px;
    width: 100%;
  }

  .slick-arrow {
    background: none;
    align-items: center;
    border: none;
    display: flex;
    justify-content: center;
    font-size: 0;
    padding: 0 0 0 2px;
    position: absolute;
    bottom: 9px;
    width: 35px;
    height: 35px;
    opacity: .5;
    transition: opacity .2s ease;

    &::after {
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      transform-origin: 50% 50%;
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 1;
    }
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
    transform: rotate(180deg);
  }

  .slick-dots {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
      opacity: 0.3;
      margin: 0 2px;
      height: 50px;

      &:first-child:last-child {
        display: none;
      }

      &.slick-active {
        opacity: 1;
      }

      @media (--tablet-up) {
        height: 80px;
      }
    }

    button {
      background: none;
      border: 0;
      padding: 0;
      width: 10px;
      height: 10px;
      -webkit-appearance: none;
      -moz-appearance: none;

      &::before {
        background: #000;
        border-radius: 50%;
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        margin: 0 auto;
      }

      &:focus {
        outline: none;
      }
    }
  }

  @media (--tablet-up) {
    .slick-arrow {
      bottom: 15px;
    }

    .slick-prev {
      left: 10%;
    }

    .slick-next {
      right: 10%;
    }

    &__slider {
      &:not(.slick-initialized) {
        display: flex;

        .product-images__slide {
          width: 50%;

          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    .slick-arrow {
      height: 50px;
      width: 50px;

      &::after {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (--laptop-up) {
    &__slider {
      &:not(.slick-initialized) {
        .product-images__slide {
          width: 33.33%;

          &:nth-child(3) {
            display: block;
          }
        }
      }
    }
  }

  @media (--hd) {
    &__slider {
      &:not(.slick-initialized) {
        .product-images__slide {
          width: 25%;

          &:nth-child(4) {
            display: block;
          }
        }
      }
    }
  }
}

.sizes {
  background: #ededed;
  padding: 86px 0 30px;
  position: relative;

  &-title {
    font-family: "Neutra Text TF", sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .1em;
    text-align: center;
    text-transform: uppercase;
  }

  &__view {
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 66%;
    }

    img {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      transition: opacity .3s ease, visibility 0s .3s ease;
    }

    div {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .visible img {
      opacity: 1;
      visibility: visible;
      transition: opacity .3s .2s ease, visibility 0s .2s ease;
    }
  }

  &__heading,
  &__selector,
  &__watch {
    padding: 0 15px;
  }

  &__heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .selector {
    &__head {
      border-bottom: 2px solid rgba(181, 181, 181, .3);
      color: #5b5b5b;
      display: flex;
      font-family: "Neutra Text TF", sans-serif;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: .1em;
      text-transform: uppercase;

      a {
        position: relative;
        padding: 0 12px;
        width: 50%;

        &::after {
          background: #cab49b;
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -2px;
          transform-origin: 0 50%;
          transform: scaleX(0);
          transition: all .2s ease;
          left: 0;
        }

        &.active {
          color: #1c1c1c;

          &::after {
            transform: scaleX(1);
          }
        }

        &:hover {
          color: #1c1c1c;
          text-decoration: none;
        }
      }
    }

    &__body {
      position: relative;

      ul {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s 0s ease, visibility 0s .3s;

        &.visible {
          opacity: 1;
          visibility: visible;
          transition: opacity .3s .2s ease, visibility 0s .2s;
        }
      }
    }

    .size {
      transition: all .2s ease;

      &__title {
        font-family: "Neutra Text TF", sans-serif;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .1em;
        text-transform: uppercase;
      }

      i {
        background: #e2e2e2;
        color: #a2a2a2;
        display: inline-block;
        border-radius: 50%;
        height: 28px;
        width: 28px;
        line-height: 26px;
        letter-spacing: 0;
        text-align: center;
        font-style: normal;
        padding-top: 2px;
        transition: all .2s ease;
      }

      &:hover,
      &.active {
        background-color: rgba(202, 180, 155, .2);
        text-decoration: none;
        color: #856c4f;

        i {
          background: #cab49b;
          color: #856c4f;
        }
      }
    }

    &__shop-btn {
      .btn:not(.visible) {
        display: none;
      }
    }
  }

  @media (--mobile) {
    &__view {
      img {
        height: 100%;
        object-position: 59% 50%;
        object-fit: cover;
      }
    }

    .selector__body {
      margin-bottom: 20px;
    }
  }

  @media (--tablet-up) {
    &__heading {
      .title {
        margin-bottom: 10px;
      }
    }

    &__view {
      &::after {
        padding-bottom: 40%;
      }
    }

    &__watch {
      .sizes-title {
        margin-bottom: 28px;
      }

      ul {
        display: flex;
        justify-content: center;
      }

      li {
        margin: 0 15px;
      }

      a {
        background: no-repeat 50% 0 / 100%;
        display: block;
        width: 90px;
        height: 90px;
        position: relative;
        transition: background-position 0s 0s;

        &::after {
          background: #b09d88;
          border-radius: 50%;
          content: '';
          height: 72px;
          width: 72px;
          opacity: 0;
          position: absolute;
          left: calc(50% - 36px);
          top: calc(50% - 36px);
          transform: scale(.5);
          transition: all .3s ease;
        }

        &.active {
          background-position: 50% 100%;
          transition: background-position 0s .3s;

          &::after {
            opacity: .2;
            transform: scale(1);
          }
        }
      }
    }
  }

  @media (--tablet-down) {
    &__heading {
      .title {
        font-size: 32px;
        margin-bottom: 0;
      }
    }

    .selector {
      margin: 0 auto;
      max-width: 400px;
      padding: 4px 0 30px;

      &__head {
        font-size: 15px;

        a {
          padding: 0 0 9px;
          text-align: center;
        }
      }

      &__body {
        padding: 22px 0 11px;
        min-height: 126px;

        ul {
          display: flex;
          left: -10px;
          right: -10px;
          top: 22px;
          bottom: 11px;
        }

        li {
          width: 33.33%;
          padding: 0 10px;
        }
      }

      .size {
        display: block;
        border-radius: 16px;

        &__title {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 18px 15px 15px;
        }

        i {
          display: block;
          margin-bottom: 13px;
        }
      }

      &__info {
        letter-spacing: 0;
        position: relative;
        text-align: center;
        min-height: 50px;

        > div {
          position: absolute;
          width: 100%;
          top: 0;
          transition: all .3s ease;

          &:not(.visible) {
            visibility: hidden;
            opacity: 0;
          }

          &.visible {
            transition: all .3s .2s ease;
          }
        }
      }

      &__shop-btn {
        text-align: center;
      }
    }
  }

  @media (--laptop-up) {
    padding: 170px 0 100px;

    &__heading,
    &__selector,
    &__watch {
      padding: 0 20px;
      margin: 0 auto;
      max-width: 1345px;
    }

    &__inner {
      min-height: 340px;
      margin-bottom: 10px;
    }

    &__view {
      width: 52%;
      position: absolute;

      img {
        width: 100%;
      }
    }

    &__selector {
      display: flex;
      justify-content: center;
      min-height: 270px;
      padding-left: 50%;
    }

    .selector {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 350px;

      &__head {
        a {
          padding: 0;
          text-align: left;
          width: auto;
          height: 28px;
          margin-right: 20px;
        }
      }

      &__body {
        padding-top: 25px;
        min-height: 137px;

        a {
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          height: 28px;
          padding-right: 6px;
        }

        ul {
          top: 25px;
          bottom: 0;
          width: 100%;
        }

        li {
          margin-bottom: 14px;
        }
      }

      &__shop-btn {
        margin-top: 30px;
      }
    }

    .size {
      line-height: 28px;

      i {
        margin-right: 8px;
      }

      &__description {
        padding-top: 1px;
        letter-spacing: 0;
      }
    }
  }

  @media (--desktop-up) {
    padding-bottom: 125px;

    &__selector {
      min-height: 300px;
      padding-left: 54%;
    }

    .selector {
      padding-bottom: 10px;

      &__head {
        a {
          margin-right: 45px;
        }
      }
    }

    &--simple {
      padding: 128px 0 114px;

      .sizes__heading {
        margin-bottom: 46px;
      }

      .sizes__view {
        width: 50%;
      }

      .sizes__selector {
        padding-left: 42%;
      }
    }
  }

  @media (--hd) {
    padding: 180px 0 200px;

    &__heading {
      margin-bottom: 40px;
    }

    &__selector {
      padding-left: 775px;
    }

    &__watch {
      padding-top: 60px;
    }
  }
}

.checkout {
  padding: 15px 0 40px;

  &__steps {
    max-width: 700px;
  }

  .step {
    background: #fff;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    &__head {
      align-items: center;
      border-bottom: 1px solid rgba(155, 155, 155, .2);
      color: #c9c9c9;
      font-size: 24px;
      letter-spacing: 0;
      padding: 18px 15px 17px;

      > div {
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;

        > div {
          display: flex;
          align-items: center;
        }
      }

      .payment-methods li {
        border: 1px solid #e7e7e7;
        border-radius: 2px;
        margin-right: 4px;
      }
    }

    &__title {
      padding-top: 5px;
    }

    &__edit {
      letter-spacing: 0;
      font-size: 14px;
      position: relative;

      &::after {
        background: #1e1c1c;
        bottom: 0;
        content: '';
        left: 0;
        height: 1px;
        width: 100%;
        opacity: .2;
        position: absolute;
        transition: all .2s ease;
      }

      &:hover {
        text-decoration: none;

        &::after {
          opacity: 1;
        }
      }
    }

    &__number {
      border: 1px solid currentColor;
      border-radius: 50%;
      display: inline-block;
      font-size: 15px;
      flex-shrink: 0;
      line-height: 26px;
      margin-right: 15px;
      padding-top: 1px;
      position: relative;
      height: 27px;
      width: 27px;
      text-align: center;
      overflow: hidden;

      &::before {
        background: #ffffff;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &::after {
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        content: '';
        left: 8px;
        top: 8px;
        position: absolute;
        opacity: 0;
        width: 9px;
        height: 6px;
        transform: rotate(-50deg);
      }
    }

    &__columns {
      padding: 16px 15px 5px;
      letter-spacing: 0;

      > div {
        margin-bottom: 30px;
      }

      a {
        position: relative;

        &::after {
          background: currentColor;
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
        }
      }
    }

    .step__overview {
      line-height: 1.6;
      font-size: 14px;
      padding: 7px 45px;
      display: none;
      color: #afafaf;
      letter-spacing: 0;
    }

    .checkout-form {
      display: none;
    }

    &.passed {
      .step__head {
        color: #1c1c1c;
      }

      .step__number {
        &::before,
        &::after {
          opacity: 1;
        }
      }

      .step__overview {
        display: block;
      }
    }

    &.active {
      .step__head {
        color: #1c1c1c;
      }

      .step__short-view {
        display: none;
      }

      .step__overview {
        display: none;
      }

      .checkout-form {
        display: block;
      }
    }
  }

  &__note {
    font-style: italic;
    color: #afafaf;
    letter-spacing: 0;
    text-align: center;
  }

  .payment-methods {
    display: flex;

    li {
      background: url('/assets/images/payments.jpg') no-repeat 50% 0 / 42px 150px;
      width: 42px;
      height: 25px;
      margin-right: 4px;

      &.large {
        width: 72px;
        height: 43px;
        background-size: 73px 258px;
      }
    }
  }

  &__overview-returns {
    text-align: center;
    padding: 20px 10px 4px;

    a {
      display: inline-block;
      position: relative;
      opacity: .7;
      line-height: 1.5;
      letter-spacing: 0;

      &::after {
        background: currentColor;
        content: '';
        height: 1px;
        opacity: .2;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        transition: all .2s ease;
      }

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }
  }

  &--success {
    .checkout__inner {
      margin-bottom: 26px;
    }

    .btn {
      min-width: 210px;
    }
  }

  @media (--tablet-up) {
    padding: 30px 0 60px;

    .step {
      &__head {
        padding: 19px 45px 16px 33px;
      }

      &__columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 27px 35px 3px;

        .title {
          margin-bottom: 10px;
        }

        >div {
          flex-grow: 1;
          max-width: 265px;
          width: calc(50% - 20px);
          margin: 0 20px 54px 0;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }

    &--success {
      padding: 30px 0 126px;
    }
  }

  @media (--laptop-down) {
    &__overview,
    &__steps {
      margin: 0 auto 16px;
      max-width: 842px;
    }
  }

  @media (--mobile) {
    .step {
      &__title {
        font-size: 18px;
      }
    }
  }

  @media (--desktop-up) {
    &__inner {
      display: flex;
      align-items: flex-start;
    }

    &__steps {
      flex-grow: 1;
    }

    &__overview {
      flex: 1 0 395px;
      margin-left: 15px;
      order: 2;
    }
  }
}

.stripe-form {
  padding-bottom: 20px;

  #card-error {
    color: red;
    
    &:not(:empty) {
      padding-top: 10px;
    }
  }

  .result-message {
    margin-top: 20px;
  }
}

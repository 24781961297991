.checkbox,
.radio {
  align-items: center;
  display: flex;
  line-height: 18px;
  height: 100%;
  padding: 3px 0 0 28px;
  position: relative;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    position: absolute;
    width: 100%;
    left: 0;

    &:focus,
    &:checked {
      outline: none;
    }
  }

  &::before {
    border: 1px solid #e1e1e1;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
  }

  input:checked  {
    &+.checkmark {
      opacity: 1;
    }

    &~span {
      font-weight: 500;
    }
  }
}

.checkbox {
  align-items: flex-start;
  padding-bottom: 8px;

  &::before {
    height: 18px;
    width: 18px;
    top: 1px;
    //bottom: 11px;
  }

  //.checkmark {
  //  top: auto;
  //  bottom: 18px;
  //}
}

.radio {
  &::before {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    top: calc(50% - 7px);
  }
}

.checkmark {
  position: absolute;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  width: 9px;
  height: 5px;
  opacity: 0;
  transform: rotate(-45deg);
  left: 5px;
  top: 7px;
}

.checkmark--radio {
  background: currentColor;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: calc(50% - 3px);
  left: 4px;
}

.slick-list {
  width: 100%;
}

.slick-slide,
.slick-arrow {
  &:focus {
    outline: none;
  }
}

.slick-slide {
  height: auto !important;

  &:focus {
    outline: none
  }
}

.title {
  font-size: 26px;
  font-weight: normal;
  letter-spacing: .1em;
  line-height: 1.2;
  margin-bottom: 10px;
  text-transform: uppercase;

  &--light {
    font-size: 34px;
    font-weight: 300;
    text-transform: unset;
    letter-spacing: 0;
  }

  &--light-smaller {
    font-size: 28px;
    font-weight: 300;
    text-transform: unset;
    letter-spacing: 0;
  }

  &--large {
    font-weight: 300;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  &--small {
    font-family: 'Neutra Text TF', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .1em;
    margin-bottom: 16px;
  }

  @media (--tablet-up) {
    font-size: 34px;

    &--light,
    &--large {
      font-size: 42px;
    }

    &--light-smaller {
      font-size: 34px;
    }

    &--small {
      font-size: 14px;
    }
  }
}

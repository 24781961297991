@media (--laptop-up) {
  .subheader {
    background: #ffffff;
    color: #1b1b1b;
    font-size: 13px;
    letter-spacing: 0;
    position: fixed;
    top: 20px;
    width: 100%;
    z-index: 9;
    transition: all .3s ease;

    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      padding-top: 5px;
      transition: all .3s ease;
    }

    li {
      padding: 0 15px;
      text-transform: capitalize;

      @media (--desktop-up) {
        padding: 0 25px;
      }
    }

    a {
      position: relative;

      &::after {
        background: #9c8265;
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scaleX(0);
        transition: all .3s ease
      }

      &:hover {
        text-decoration: none;

        &::after {
          opacity: 1;
          transform: scaleX(1);
        }
      }

      &.active {
        font-weight: 500;

        &::after {
          opacity: 1;
          transform: scaleX(1);
        }
      }
    }

    &--product {
      background: #121212;
      color: #fff;
      opacity: 0;
      transform: translateY(-200%);
      visibility: hidden;
      transition: opacity .3s ease, transform .3s ease, visibility 0s .3s;

      &.shown {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        transition: opacity .3s ease, transform .3s ease, visibility 0s 0s;
      }
    }

    &__product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1108px;
      padding: 8px 0;
    }

    &__product-title {
      font-size: 22px;
      font-weight: 300;
    }

    &__product-purchase {
      display: flex;
      align-items: center;

      .btn {
        min-width: 0;
        padding: 10px 20px 6px;
      }
    }

    &__product-price {
      margin-right: 20px;
    }
  }

  .subheader.info {
    top: 68px;
  }

  .scrolled + .subheader {
    transform: translateY(-35px);

    .subheader__inner {
      transform: translateY(5px);
    }

    a {
      &::after {
        transform: scaleX(0) translateY(-3px);
      }

      &.active::after {
        transform: scaleX(1) translateY(-3px);
      }
    }

    &--product {
      transform: translateY(-75px);

      &.shown {
        transform: translateY(-20px);
      }
    }
  }
}

.center {
  padding: 0 15px;

  @media (--laptop-up) {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1345px;

    &--slim {
      max-width: 1010px;
    }

    &--narrow {
      max-width: 1150px;
    }

    &--wide {
      max-width: 1440px;
    }
  }
}

.empty-cart {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  &__image {
    background: url('/assets/images/empty-cart.png') 50% 50% / contain;
    margin-bottom: 10px;
    height: 60px;
    width: 60px;
  }

  &__title {
    margin-bottom: 16px;
  }

  a {
    font-style: italic;
    padding-right: 12px;
    position: relative;

    &::after {
      background: #1e1c1c;
      content: '';
      position: absolute;
      bottom: 1px;
      height: 1px;
      opacity: .2;
      left: 0;
      right: 12px;
    }

    svg {
      width: 14px;
      height: 14px;
      position: absolute;
      right: -3px;
      top: calc(50% - 7px);
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

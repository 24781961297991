.main {
    background: #ededed;

    &__inner {
        //overflow: auto;
        transition: transform .3s cubic-bezier(.975,.015,.46,.99);
        transform-origin: 50% 50vh;
        padding-bottom: 1px;
        margin-bottom: -1px;
    }
}

:root {
  --max-mobile-width: 767px;
  --min-tablet-width: 768px;
  --max-tablet-width: 998px;
  --min-laptop-width: 999px;
  --max-laptop-width: 1199px;
  --min-desktop-width: 1200px;
  --max-desktop-width: 1599px;
  --min-hd-width: 1600px;
}

@custom-media --mobile (width <= 767px);
@custom-media --tablet-down (width <= 998px);
@custom-media --tablet (width >= 768px) and (width <= 998px);
@custom-media --tablet-up (width >= 768px);
@custom-media --laptop-down (width <= 1199px);
@custom-media --laptop (width >= 999px) and (width <= 1199px);
@custom-media --laptop-up (width >= 999px);
@custom-media --desktop-down (width <= 1599px);
@custom-media --desktop (width >= 1200px) and (width <= 1599px);
@custom-media --desktop-up (width >= 1200px);
@custom-media --hd (width >= 1600px);

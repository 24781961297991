.stars {
  display: inline-block;
  font-size: 0;
  margin: 0 -2px;
  position: relative;
  white-space: nowrap;

  i {
    background: url("/assets/images/stars.png") repeat-x 0 0 / 100% auto;
    display: inline-block;
    margin: 0 2px;
    width: 20px;
    height: 20px;
  }

  &__blank {

  }

  &__filled {
    overflow: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    width: 0;

    i {
      background-position: 0 100%;
    }
  }
}

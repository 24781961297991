.tracking {
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0 60px;
    min-height: 100vh;

    > div {
      width: 100%;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 4px;
  }

  &__text {
    color: #686868;
    margin-bottom: 28px;
    text-align: center;
  }

  &__block {
    background: #ffffff;
    color: #686868;
    margin: 0 auto 13px;
    padding: 20px 18px 20px 30px;
    max-width: 540px;
  }

  &__input {
    padding: 10px 0;
  }

  &__info {
    letter-spacing: 0;
  }

  .input {
    border: 1px solid #e1e1e1;
    flex-grow: 1;
    line-height: 28px;
    margin-right: 10px;
    padding-left: 30px;
    position: relative;

    &::before {
      color: #1c1c1c;
      content: '#';
      font-size: 24px;
      line-height: 28px;
      position: absolute;
      left: 11px;
      padding: 10px 0 0;
      top: 0;
    }
  }

  input,
  button {
    &:focus {
      outline: none;
    }
  }

  input {
    border: none;
    font-size: 14px;
    padding: 7px 0 3px;
    width: 100%;
  }

  .btn {
    min-width: 95px;
    padding: 13px 23px 9px;
  }

  &__title {
    color: #1c1c1c;
    font-weight: 600;
  }

  .checkpoint {
    align-items: center;
    display: flex;
    color: #686868;

    &:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 20px;
    }

    &:first-child {
      padding-top: 5px;
    }

    &:not(:first-child) {
      padding-top: 20px;
    }

    &__date {
      width: 30px;
      line-height: 1;
      margin-right: 15px;
      text-align: center;
    }

    &__day {
      font-size: 24px;
    }

    &__title {
      flex-grow: 1;
      padding-right: 10px;
    }

    &__time {
      flex-shrink: 0;
      min-width: 60px;
      font-style: italic;
      padding-top: 6px;
      text-align: right;
      letter-spacing: 0;
    }

    &.highlighted {
      color: #1c1c1c;

      .checkpoint__title {
        font-weight: 600;
      }
    }
  }

  .tracking-data {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    transition: opacity .3s ease, max-height .3s .2s ease, visibility 0s .5s;
  }

  &.entered .tracking-data {
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
    transition: opacity .3s .2s ease, max-height .3s 0s ease, visibility 0s 0s;
  }

  @media (max-width: 499px) {
    .input {
      margin-bottom: 14px;
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }

  @media (min-width: 500px) {
    &__input {
      display: flex;
    }

    .checkpoint {
      padding-left: 6px;

      &__date {
        margin-right: 30px;
      }

      &__title {
        padding-right: 25px;
      }
    }
  }

  @media (--laptop-up) {
    &__inner {
      padding: 170px 0 100px;
    }
  }
}

.promo {
  color: #fff;
  position: relative;

  .center {
    position: relative;
  }

  &__inner {
    position: relative;
  }

  &__content {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px 32px 52px;
    position: relative;
  }

  p {
    margin-bottom: 20px;
    opacity: .8;
  }

  .title, p {
    max-width: 360px;

    &::selection {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &__quote {
    display: none;
    position: absolute;
    right: 30px;
    bottom: 10%;
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    max-width: 410px;
  }

  &__bg {
    background: #131313;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--ambassador {
    margin-bottom: 20px;

    p {
      font-size: 24px;
      line-height: 1.33;
      max-width: 580px;
      letter-spacing: 0;
      font-weight: 300;
    }

    .promo__note {
      opacity: .5;
    }

    .title {
      max-width: 440px;
    }
  }

  &--light {
    color: #1c1c1c;

    .promo__bg {
      background: none;
    }
  }

  &--wide-text {
    .title,
    p {
      max-width: 510px;
    }
  }

  &--centered {
    margin-bottom: 48px;

    .promo__content {
      min-height: 436px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 70px;
      text-align: center;

      div,
      p {
        max-width: 500px;
      }
    }
  }

  .promo__inner {
    overflow: hidden;
  }

  &--large-margin {
    margin-bottom: 40px;
  }

  &--narrow {
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &--strap {
    padding-top: 60px;

    .item__image {
      margin-bottom: 46px;

      &::after {
        padding-bottom: 32%;
      }
    }
  }

  &--individual {
    .line {
      position: absolute;
      left: 50%;
      bottom: -60px;
      margin-bottom: 0!important;
    }
  }

  @media (--mobile) {

    &__content {
      padding-bottom: 26px;
      min-height: 500px;
    }

    .title {
      font-size: 32px;
    }

    .line {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 18px;
      max-width: 90%;
    }

    &--narrow {
      .promo__content {
        min-height: 136vw;
      }
    }

    //&--no-margin {
    //  margin-bottom: 0;
    //}
  }

  @media (--tablet-up) {
    &__content {
      padding: 40px;
    }

    &--centered {
      margin-bottom: 100px;
    }

    &--individual {
      .promo__content {
        min-height: 340px;
      }

      .line {
        bottom: -80px;
      }
    }
  }

  @media (--tablet) {
    &__content {
      min-height: 580px;
      padding: 120px 40px;
    }
  }

  @media (--tablet-down) {
    &--individual:last-child {
      margin-bottom: 0;
    }
  }

  @media (--laptop-up) {
    &--individual {
      .promo__content {
        min-height: 465px;
      }
    }
  }

  @media (--desktop-up) {
    &__content {
      padding: 40px 88px;
      min-height: 490px;
      max-width: 1306px;
      margin: 0 auto;
    }

    &__quote {
      display: block;
    }

    &:last-child {
      margin-bottom: 138px;
    }

    &--narrow {
      .promo__inner {
        margin: 0 auto;
        max-width: 1305px;
      }

      .promo__content {
        min-height: 490px;
      }
    }

    &--ambassador {
      margin-bottom: 32px;

      .promo__content {
        padding: 48px 96px 40px;
        min-height: 465px;
      }

      .title {
        margin-bottom: 24px;
      }
    }

    &--wide-text {
      .promo__content {
        min-height: 584px;
        max-width: 1285px;
        margin: 0 auto;
      }
    }

    &--large-margin {
      margin-bottom: 76px;
    }

    &--centered {
      margin-bottom: 142px;

      .promo__content {
        min-height: 568px;
      }
    }

    &--individual {
      .line {
        bottom: -110px;
      }
    }
  }

  @media (--hd) {
    &--wide-text,
    &--narrow {
      .promo__inner {
        &::after {
          content: '';
          display: block;
          padding-bottom: 37.5%;
        }
      }

      .promo__content {
        position: absolute;
        min-height: 0;
        top: 0;
        bottom: 0;
        left: calc(50% - 643px);
        width: 1286px;
      }
    }

    &--narrow {
      padding: 0 68px;

      .promo__inner {
        max-width: none;
      }

      .promo__content {
        left: calc(50% - 653px);
        width: 1306px;
      }
    }
  }
}

.btn {
  background: rgba(169, 137, 112, 0);
  border: 1px solid rgb(202, 180, 155);
  color: currentColor;
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  line-height: 1.2;
  min-width: 170px;
  padding: 16px 26px 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all .3s ease;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    top: 1px;
    left: -3px;
    right: -3px;
    bottom: 1px;
    border: 1px solid rgba(202, 180, 155, .7);
  }

  &::after {
    top: 50%;
    left: 0;
    right: 0;
    bottom: auto;
    transform: translateY(-50%);
    opacity: 0;
    height: 100%;
  }

  &:hover {
    background: rgba(169, 137, 112, 0.3);
    text-decoration: none;
  }

  &--lighter {
    border-color: rgb(228, 199, 165);

    &::before {
      border-color: rgba(228, 199, 165, .7);
    }
  }

  &--solid {
    border-color: transparent;
    background: #c1a98f;
    color: #ffffff;
    font-weight: 700;
    padding: 16px 30px 12px;

    &::before {
      display: none;
    }

    &:hover {
      background: rgba(202, 180, 155, 0.8);
    }
  }

  &--wide {
    width: 100%;
  }

  &--family {
    color: #c8b199;
    border-color: #c8b199;
    letter-spacing: 0.1em;
    padding: 12px 15px 8px !important;
    font-weight: 600;
    font-size: 14px;
    min-width: 120px;

    &::before {
      display: none;
    }
  }

  @media (--mobile) {
    font-size: 16px;
    padding: 18px 28px 14px;
  }

  @media (--tablet-up) {
    padding: 14px 30px 10px;
  }
}

.large-menu {
  max-width: 220px;

  li {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.85;
    letter-spacing: 0;
    text-transform: capitalize;
    margin-bottom: 1px;
    position: relative;
  }

  svg {
    fill: #a19081;
    height: 10px;
    width: 10px;
    opacity: 0;
    position: absolute;
    right: -25px;
    top: calc(50% - 5px);
    transform: rotate(-90deg) translateY(-5px);
    transition: all .4s ease-out;
  }

  a {
    span {
      display: inline-block;
      position: relative;

      &::after {
        background: currentColor;
        bottom: 6px;
        content: '';
        opacity: 0;
        left: 0;
        height: 1px;
        width: 100%;
        position: absolute;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: all .4s ease-out;
      }
    }

    &:hover {
      text-decoration: none;

      span::after {
        transform: scaleX(1);
        opacity: 1;
      }

      svg {
        opacity: 1;
        transform: rotate(-90deg) translateY(0);
      }
    }
  }

  @media (--mobile) {
    li {
      font-size: 26px;

    }
  }

  @media (--desktop-up) {
    svg {
      right: -50px
    }
  }
}

.form {
  padding-top: 10px;
}

@media (--tablet-up) {
  .form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
  }
}


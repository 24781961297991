.subheader__product-price {
  font-size: 15px;
}
.info-block {
  .item {
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:not(.item--text) {
      background: #dadada;
    }

    &__subtitle {
      font-family: "Neutra Text TF", sans-serif;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: .15em;
      margin-bottom: 6px;
      text-transform: uppercase;
    }

    &__image {
      position: relative;
      margin-bottom: 18px;

      &::after {
        content: '';
        display: block;
        padding-bottom: 70%;
      }
    }

    p {
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 15px;
    }
  }

  @media (--tablet-down) {
    &__items {
      margin: 0 auto;
      max-width: 600px;
    }

    .item {
      padding-bottom: 25px;

      &--secondary {
        display: none;
      }
    }
  }

  @media (--laptop-up) {
    margin-bottom: 80px;

    &__items {
      display: flex;
      margin: 0 -5px;
    }

    .item {
      flex-grow: 1;
      margin: 0 5px;
      width: calc(50% - 10px);

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      &--text {
        display: flex;
        align-items: center;

        &::after {
          display: none;
        }
      }

      &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        max-width: 300px;
      }
    }

    &--small-margin {
      margin-bottom: 40px;
    }
  }

  @media (--desktop-up) {
    margin-bottom: 138px;

    .item {
      max-width: 408px;

      &__inner {
        max-width: 330px;
        padding-left: 30px;
      }

      &::after {
        padding-bottom: 120%;
      }

      &--text {
        max-width: 468px;
      }

      &--wide {
        flex-grow: 2;
        max-width: 734px;
        width: calc(60% - 10px);

        &::after {
          padding-bottom: 67%;
        }
      }

      &--narrow {
        max-width: 362px;

        &::after {
          padding-bottom: 135%;
        }
      }

      &:last-child {
        .item__inner {
          max-width: 350px;
          padding-left: 50px;
        }
      }
    }

    &--small-margin {
      margin-bottom: 80px;
    }
  }
}

.product-text {
  background: #ffffff;
  padding: 50px 15px;

  &__inner {
    margin: 0 auto;
    max-width: 540px;
  }

  .title {
    margin-bottom: 20px;
  }

  @media (--mobile) {
    text-align: center;
  }

  @media (--tablet-up) {
    padding: 70px 0;
  }
}

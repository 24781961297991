.collections {
  padding: 16px 0;
  margin: 0 auto;
  max-width: 1350px;

  .item {
    background: #a0a0a0;
    color: #fff;
    display: block;
    font-family: "Neutra Text TF", sans-serif;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      padding-bottom: 76%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: 50% 50%;
      transition: all 5s ease;
    }

    &__title {
      font-size: 17px;
      position: absolute;
      width: 100%;
      bottom: 14px;
      text-align: center;
      transition: all .2s ease;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    @media (--tablet-down) {
      margin-bottom: 10px;

      &::after {
        padding-bottom: 82%;
      }
    }
  }

  @media (--tablet) {
    .item {
      &::after {
        padding-bottom: 50%;
      }
    }
  }

  @media (--tablet-up) {
    padding: 20px;
  }

  @media (--laptop-up) {
    &__items {
      display: flex;
      margin: 0 -7px;
    }

    .item {
      flex-grow: 1;
      margin: 0 7px;

      &__title {
        bottom: 40px;
        letter-spacing: .1em;
      }
    }
  }

  @media (--desktop-up) {
    padding: 68px 20px 46px;
  }
}

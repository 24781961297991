.cart {
  background: #f9f9f9;
  background: #f9f9f9 linear-gradient(170deg, rgba(247, 244, 240, 1) 0, rgba(221, 215, 210, 1) 60%, rgba(251, 249, 247, 1));
  background: #f9f9f9 -webkit-linear-gradient(170deg, rgba(247, 244, 240, 1) 0, rgba(221, 215, 210, 1) 60%, rgba(251, 249, 247, 1));
  color: #1b1b1b;
  position: fixed;
  right: -450px;
  top: 0;
  opacity: 0;
  width: 450px;
  height: 100%;
  transition: all .3s ease;
  z-index: 15;

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 28px 30px 25px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(197,197,197,.5);
    padding: 0 0 16px 4px;
  }

  &__body {
    overflow-y: auto;
    margin: 0 -20px;
    padding: 0 20px;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
  }

  &__empty {
    flex-grow: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__subtotal {
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-style: solid;
    border-color: rgba(197,197,197,.5);
  }

  &__subtotal {
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0;
    line-height: 1.86;
    margin-bottom: 8px;
    padding: 6px 0 2px;
  }

  &__total {
    font-weight: 500;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__checkout {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    a {
      margin: 0 10px 17px;
      position: relative;
      width: calc(100% - 20px);

      svg {
        fill: currentColor;
        height: 15px;
        opacity: .5;
        width: 15px;
        margin-top: 5px;
        transform: rotate(-90deg);
        position: absolute;
        top: calc(50% - 14px);
      }

      &.paypal,
      &.applepay,
      &.gpay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(50% - 20px);

        span {
          display: inline-block;
          width: 65px;
          height: 23px;
        }
      }

      &.applepay {
        background: #fff;

        span {
          background: url('/assets/images/applepay-black.png') no-repeat 50% 50% / contain;
        }
      }

      &.paypal {
        background: #0079c1;

        span {
          background: url('/assets/images/paypal-white.png') no-repeat 50% 50% / contain;
        }
      }

      &.gpay {
        background: #fff;
        padding: 0;

        span {
          width: 68px;
          height: 24px;
          background: url('/assets/images/payments-l.jpg') no-repeat 50% -86px / 68px auto;
        }
      }
    }

    .paypal-button-container {
      margin: 0 10px 17px;
      width: calc(50% - 20px);
    }
  }

  &__price {
    font-family: 'Montserrat', sans-serif;
  }

  &__loader {
    align-items: center;
    background: rgba(221, 215, 210, .7);
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;

    svg {
      height: 40px;
      width: 40px;
      transform: scale(1.1);
      transition: transform .5s ease;

      .minute-hand {
        transform-origin: 32px 33px;
        animation: rotate 3s linear infinite;
      }
    }
  }

  .close {
    z-index: 5;
    transform: translateY(-4px);
  }

  &.shown {
    transform: translateX(-450px) translateZ(0);
    opacity: 1;

    & + .cart-overlay {
      opacity: .5;
      visibility: visible;
      z-index: 14;
    }
  }

  &.load {
    .cart__loader {
      opacity: 1;
      visibility: visible;

      svg {
        transform: scale(1);
      }
    }
  }
}

.cart-overlay {
  opacity: 0;
  background: #1e1c1c;
  visibility: hidden;
  z-index: -1;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease;
}

.paypal-button-container {
  background: #fff;
  height: 45px;
  overflow: hidden;
  position: relative;

  &::before {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: #000;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    transform: translateZ(0);
    animation: paypal-loader 1.1s infinite linear;
    width: 20px;
    height: 20px;
  }

  @keyframes paypal-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  > .paypal-button {
    display: flex !important;
    justify-content: center !important;
    height: calc(100% + 2px) !important;
    margin: -1px -10px 0 !important;
    position: relative;
    width: calc(100% + 20px) !important;

    > .zoid-outlet {
      display: block !important;
      height: 100% !important;
      min-width: 300px !important;
    }
  }

  .zoid-component-frame {
    z-index: auto !important;
  }
}

@media (max-width: 499px) {
  .cart {
    width: 100vw;
    right: -100vw;

    &__inner {
      padding: 15px 15px 0;
    }

    &__heading {
      padding-bottom: 6px;
    }

    &__body {
      margin: 0 -15px;
      padding: 0 15px;
    }

    &__checkout {
      padding-bottom: 0;

      //a {
      //  line-height: 36px;
      //  height: 36px;
      //  margin-bottom: 10px;
      //}
    }

    &__subtotal {
      margin-bottom: 8px;
      padding: 4px 0 0;
    }

    &.shown {
      transform: translateX(-100vw);
    }
  }

  //.paypal-button-container {
  //  height: 36px !important;
  //
  //  .paypal-button img {
  //    margin-top: -4px !important;
  //  }
  //}
  //
  //.paypal-button .paypal-button-logo {
  //  transform: translateY(-15px) !important;
  //}
}

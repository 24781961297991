.bestsellers {
  padding: 16px 0 0;
  position: relative;

  .center {
    position: relative;
  }

  &__title {
    font-family: "Neutra Text TF", sans-serif;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  &__back-text {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 347px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1;
    opacity: 0.05;
    top: 16%;
    left: 0;

    @media (--laptop-down) {
      display: none;
    }
  }

  &__items {
    margin-bottom: 12px;
  }

  .item {
    &__inner {
      max-width: 210px;
      margin: 0 auto;
      display: flex;
      font-size: 18px;
      flex-direction: column;
      align-items: center;

      &:focus {
        outline: none;
      }

      &:hover {
        text-decoration: none;

        .item__title {
          color: #9c8265;
        }

        .item__image {
          transform: scale(1.05);
        }
      }
    }

    &__image {
      margin-bottom: 40px;
      transition: all .7s ease;
    }

    &__title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 7px;
      transition: all .3s ease;
    }

    &__price {
      letter-spacing: 0;
      color: #979797;
    }

  }

  .slick-slide {
    height: auto;
  }

  .slick-dots {
    display: flex;
    justify-content: center;

    li {
      margin: 0 6px;
    }

    button {
      background: #000;
      font-size: 0;
      border: none;
      height: 2px;
      width: 55px;
      opacity: 0.2;
      transition: all .1s ease;
    }

    .slick-active {
      button {
        opacity: 1;
      }
    }
  }

  @media (--mobile) {
    &__items {
      margin: 0 auto;
      width: 64%;
    }

    .slick-list {
      overflow: visible;
    }

    .item {
      &__image {
        width: 86%;
      }

      &--product {
        overflow: hidden;

        .item__image {
          margin-left: -17px;
          margin-right: -17px;
          width: auto;
        }
      }
    }
  }

  @media (--laptop-up) {
    padding: 40px 0 32px;

    &__title {
      font-size: 24px;
      margin-bottom: 92px;
    }

    &__items {
      display: flex;
      justify-content: space-around;
      max-width: 1120px;
      margin: 0 auto;
    }

    .item {
      flex-grow: 1;

      &__image {
        margin-bottom: 48px;
      }
    }
  }
}



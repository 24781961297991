.faq-container {
  padding: 40px 0 60px;

  .faq__inner {
    display: none;

    &.shown {
      display: block;
    }
  }

  &__inner {
    position: relative;
  }
}

.faq-drop,
.faq-selector {
  font-size: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 20px 20px 16px 28px;

  svg {
    fill: #9c8265;
    position: absolute;
    right: 10px;
    height: 14px;
    width: 14px;
    top: calc(50% - 7px);
    transition: all .3s ease;
  }
}

.faq-drop {
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.faq-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
}

.faq-selector {
  flex-grow: 1;
  margin-right: 25px;
  padding: 24px 20px 20px 28px;

  li {
    margin-bottom: 6px;
    cursor: pointer;
  }

  span {
    position: relative;

    &::after {
      background: currentColor;
      content: '';
      left: 0;
      bottom: -6px;
      width: 100%;
      height: 1px;
      position: absolute;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: all .2s ease;
    }
  }

  svg {
    opacity: 0;
    right: -5px;
    top: 6px;
    transform: rotate(-90deg);
  }

  div {
    display: block;
    line-height: 2;
    position: relative;
  }
}

@media (--tablet-down) {
  .faq-drop,
  .faq-selector {
    background: #1e1c1c;
    color: #fff;
  }

  .faq-drop {
    span {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .faq-selector {
    background: rgba(30, 28, 28, .9);
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    margin-right: 0;

    &.shown {
      display: block;
      z-index: 10;

      &+ .faq-overlay {
        visibility: visible;
      }
    }
  }

  //.faq-block {
  //  padding: 30px 0 0;
  //}
}

@media (--tablet) {
  .faq-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (--laptop-up) {
  .faq-container {
    padding: 62px 0 100px;

    &__inner {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
    }

    .faq-block {
      flex-grow: 1;
      max-width: 730px;
      padding: 0;
    }
  }

  .faq-selector {
    padding-top: 0;

    li {
      margin-bottom: 16px;

      &:hover,
      &.active {
        span::after {
          transform: scaleX(1);
        }

        svg {
          opacity: 1;
        }
      }

      &.active {
        font-weight: 600;
      }
    }
  }
}

@media (--desktop-up) {
  .faq-container {
    padding: 62px 0 200px;
  }
}

.full-popup {
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: all .3s .2s ease;
  z-index: 20;

  &__close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 25px;
    height: 25px;

    &::after,
    &::before {
      background: currentColor;
      content: '';
      width: 22px;
      height: 1px;
      position: absolute;
      transform-origin: 50% 50%;
      top: 50%;
      left: calc(50% - 11px);
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  &__scroll {
    width: 100%;
    height: 100%;
  }

  &__inner {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100%;
  }

  &__content {
    background: #ffffff;
    opacity: 0;
    padding: 30px;
    position: relative;
    transform: scale(1.1);
    transition: all .3s 0s ease;
    z-index: 1;

    > * {
      margin-bottom: 15px;
    }
  }

  ul {
    padding-left: 15px;
  }

  li:not(:last-child) {
    margin-bottom: 15px;
  }

  &__subtitle {
    font-family: 'Neutra Text TF', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__overlay { 
    background: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &.shown {
    opacity: 1;
    visibility: visible;
    transition: all .3s ease;

    .full-popup__content {
      opacity: 1;
      transform: scale(1);
      transition: all .3s .2s ease;
    }
  }

  @media (--mobile) {
    &__inner {
      height: 100%;
    }

    &__content {
      overflow: auto;
      height: 100%;
    }
  }

  @media (--tablet-up) {
    &__scroll {
      overflow: auto;
    }

    &__inner {
      padding: 20px;
    }

    &__content {
      padding: 40px 60px;
      max-width: 75%;

      ul {
        padding-left: 25px;
      }

      > * {
        margin-bottom: 25px;
      }
    }
  }

  @media (--laptop-up) {
    &__content {
      padding: 40px 80px;
    }
  }

  @media (--desktop-up) {
    &__content {
      padding: 60px 100px;
    }
  }
}

.warranty {
  min-height: 100vh;
  padding: 100px 0;
  display: flex;
  align-items: center;

  .center {
    flex-grow: 1;
  }

  &__login,
  &__main {
    display: none;

    &.active {
      display: block;
    }
  }

  &__login {
    .btn {
      padding: 14px 18px 10px;
    }
  }

  &__head {
    max-width: 560px;
    margin: 0 auto 25px;
    text-align: center;
  }

  .title {
    margin-bottom: 4px;
  }

  &__text {
    opacity: .8;
  }

  &__input {
    background: #ffffff;
    margin: 0 auto;
    max-width: 600px;
    padding: 25px 32px 7px;
  }

  &__columns {
    max-width: 950px;
    margin: 0 auto;
    width: 100%;
  }

  .column {
    background: #ffffff;
    margin: 0 auto 25px;
    max-width: 446px;

    &__head {
      border-bottom: 1px solid rgba(155, 155, 155, .2);
      font-size: 24px;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0;
      padding: 15px 15px 10px;
    }

    &__items {
      padding: 5px 0 14px;
    }

    &__footer {
      border-top: 1px solid rgba(155, 155, 155, .2);
      display: flex;
      align-items: center;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 1.33;
      justify-content: space-between;
      padding: 15px 15px 10px;

      span {
        color: #9c8265;
        font-weight: 600;
      }

      div {
        padding-right: 10px;
      }
    }

    &__details {
      background: #f8f8f8;
      padding: 15px 15px 10px;
    }

    &__details-title {
      font-weight: 600;
      margin-bottom: 6px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &__details-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 0;
      margin-bottom: 2px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    padding: 0 16px 0 5px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &__image {
      width: 80px;
      height: 80px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__info {
      flex-grow: 1;
      padding-right: 10px;
    }

    &__price {
      font-size: 16px;
      font-weight: 600;
      color: #9c8265;
      padding-left: 6px;

      .del {
        color: #1c1c1c;
        font-size: 14px;
        margin-right: 10px;
        opacity: 0.5;
        position: relative;


        &::after {
          background: currentColor;
          content: '';
          left: -2px;
          right:-2px;
          top: 45%;
          height: 1px;
          position: absolute;
        }
      }
    }

    .btn {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.05em;
      min-width: 120px;
      padding: 14px 10px 10px;
    }

    &--strap {
      .item__image {
        img {
          transform-origin: 50% 50%;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .special {
    .item {
      &__image {
        width: 70px;
        height: 70px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 424px) {
    .item {
      flex-wrap: wrap;
      padding: 0 8px;

      &__image {
        margin-bottom: 10px;
      }

      &__info {
        width: calc(100% - 80px);
        margin-bottom: 10px;
      }

      a {
        margin: 0 auto 15px 80px;
      }
    }

    .column {
      &__footer {
        flex-wrap: wrap;

        div {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (--laptop-up) {
    padding: 100px 0 140px;

    &__head {
      margin-bottom: 40px;
    }

    &__columns {
      display: flex;
    }

    .column {
      width: calc(50% - 28px);
      margin: 0 14px;

      &__head {
        padding: 24px 26px 16px;
      }

      &__footer {
        padding: 20px 26px 20px;
      }

      &__details {
        padding: 18px 26px 14px;
      }
    }

    .item {
      padding-right: 28px;
      margin-bottom: 0!important;

      &__image {
        width: 115px;
        height: 115px;
      }
    }

    .special {
      .item {
        padding: 15px 28px 15px 18px;

        &__image {
          width: 85px;
          height: 85px;
        }
      }
    }
  }
}

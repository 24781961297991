.pagination {
  color: #1c1c1c;

  a {
    color: inherit;
    text-decoration: none;
    position: relative;
  }

  svg {
    flex-shrink: 0;
    fill: currentColor;
    height: 15px;
    width: 15px;
    position: relative;
    top: -.05em;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__prev,
  &__next {
    flex-shrink: 0;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    height: 35px;
    letter-spacing: 0;
    position: relative;

    @media (--tablet-up) {
      font-size: 24px;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 0;
      height: 1px;
      bottom: 0;
      background-color: currentColor;
      transition: width 0.3s;
    }

    &:after {
      left: auto;
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 50%;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;

      &::after,
      &::before {
        display: none;
      }
    }
  }

  &__prev {
    text-align: left;

    span {
      margin-left: 10px;
    }

    svg {
      transform: scaleX(-1);
    }
  }

  &__next {
    text-align: right;

    span {
      margin-right: .45em;
    }
  }

  &__pages {
    flex-grow: 1;
    font-family: Arial, sans-serif;
    font-size: 18px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 10px;
    text-align: center;

    a {
      border: solid 1px transparent;
      display: inline-block;
      padding: 15px;
      margin: 3px;

      &:hover {
        border-color: #aaa;
      }

      &.active {
        border-color: currentColor;
      }
    }
  }
}



.short-review {
  margin: 20px 0 10px;
  text-align: center;

  &__title {
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-bottom: 5px;
  }

  &__stars {
    margin-bottom: 5px;
  }

  &__link {
    a {
      display: inline-block;
      font-size: 14px;
      line-height: 25px;
      opacity: .5;
      text-decoration: underline;
    }
  }
}

.contact-success {
  padding: 35px 20px;
  text-align: center;
  width: 500px;

  &::before {
    background: url('/assets/images/sent-illustration.png') no-repeat 50% 50% / contain;
    content: '';
    display: block;
    max-width: 300px;
    margin: 0 auto 10px;
    width: 100%;
    height: 88px;
  }

  &__title {
    color: #1b1717;
    font-size: 24px;
    line-height: 1.22;
    text-transform: uppercase;

    &::first-letter {
      font-size: 1.3em;
      letter-spacing: -0.1em;
    }

    span {
      display: inline-block;
    }
  }

  &__text {
    margin-bottom: 25px;
  }
}

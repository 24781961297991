.lookbook {
  background: #f9f9f9;
  overflow: hidden;
  padding-top: 32px;

  &__wide,
  &__single,
  .item {
    overflow: hidden;
    margin-bottom: 32px;
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: 50% 50%;
      transition: all 4s ease;
    }
  }


  &__wide {
    position: relative;
  }

  .item {
    max-width: 640px;
    margin: 0 auto 32px;

    &__image {
      max-width: 250px;
      margin: 0 auto;
      position: relative;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        transition: opacity .3s ease;
      }

      .secondary {
        z-index: -1;
        opacity: 0;
        transition: opacity .3s ease, z-index 0s .3s;
      }

      &:hover {
        img {
          opacity: 0;
        }

        .secondary {
          z-index: 0;
          opacity: 1;
          transition: opacity .3s ease, z-index 0s 0s;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-size: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-align: center;
    }

    &__specs {
      color: #979797;
      font-style: italic;
      margin-bottom: 5px;
      text-align: center;
    }

    &__price {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      letter-spacing: 0;
      margin-bottom: 4px;
    }

    &--product {
      background: #fff;
      padding: 20px;

      &::after {
        display: none;
      }
    }
  }

  @media (--mobile) {
    &__grid {
      max-width: 500px;
      margin: 0 auto 32px;
    }

    .item {
      &__image {
        margin-bottom: 20px;
      }

      &--product {
        padding: 40px 20px;
      }
    }
  }

  @media (--tablet-up) {
    &__wide {
      &::after {
        padding-bottom: 44%;
      }
    }

    &__single {
      &::after {
        padding-bottom: 37.5%;
      }
    }

    &__grid {
      padding: 0 15px;
    }

    .item {
      &--product {
        display: flex;
        align-items: center;
        padding: 20px;
      }

      &__info {
        padding-top: 14px;
        width: 55%;
      }

      &__image {
        width: 55%;
      }
    }
  }

  @media (--tablet-down) {
    display: flex;
    flex-direction: column;

    > * {
      width: 100%;
    }

    &__grid {
      order: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }

    &__column-watch {
      order: 1;
      display: flex;
      flex-direction: column;
    }

    .item {
      width: 100%;

      &--product {
        order: 1;
        margin-bottom: 0;
      }
    }
  }


  @media (--laptop-up) {
    &__single,
    &__grid {
      margin: 0 auto 46px;
      max-width: 1305px;
    }

    &__grid {
      display: flex;
      margin: 0 auto;
      padding: 0;
      max-width: 1327px;
    }

    &__column {
      margin: 0 11px;
      width: calc(50% - 22px);
    }

    &__wide {
      &::after {
        padding-bottom: 44%;
      }
    }

    .item {
      margin-bottom: 22px;

      &::after {
        padding-bottom: 56%;
      }

      &--high {
        &::after {
          padding-bottom: 116%;
        }
      }

      &--product {
        height: calc(50% - 22px);
      }
    }
  }

  @media (--desktop-up) {
    .item {
      &--product {
        padding: 40px 50px 40px 40px;
      }
    }
  }
}

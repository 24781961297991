.simple-btn {
  background: #c0a98f;
  border: 0;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  height: 42px;
  line-height: 42px;
  padding: 2px 20px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  span {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    background: #fff;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .2s ease;
    transform: scale(.5);
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &::before {
      opacity: 0.1;
      transform: scale(1);
    }
  }

  &--lg {
    height: 45px;
    line-height: 45px;
  }
}

.product {
  padding: 56px 0 0;

  &__inner {
    position: relative;
    overflow: hidden;
  }

  .title {
    text-align: center;
    padding: 0 20px;
  }

  &__subtitle {
    color: #acacac;
    font-size: inherit;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 28px;
  }

  &__simple-text,
  &__for {
    text-align: center;
  }

  &__simple-text {
    margin-bottom: 35px;
  }

  &__for {
    margin-bottom: 6px;
  }

  &__bg {
    div {
      position: relative;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__slider {
    position: relative;

    .slick-list,
    .slick-track {
      height: 100%;
    }
  }

  &__slider-inner {
    height: 100%;

    &:not(.slick-initialized) {
      > div {
        &:nth-child(n+2) {
          display: none;
        }
      }
    }
  }

  &__slider-counter {
    bottom: 5%;
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
  }

  &__slide {
    height: 100% !important;
  }

  &__info {
    background: #ffffff;
    padding: 28px 15px;
    position: relative;

    > div {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .title {
      padding: 0;
    }
  }

  &__variations {
    display: flex;
    justify-content: center;
  }

  &__purchase-block {
    margin-bottom: 15px;
    padding-top: 18px;

    a {
      margin-bottom: 16px;
      position: relative;
    }
  }

  &__price {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: 14px;
    position: relative;
    text-align: center;

    &::before {
      background: currentColor;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      top: 41%;
    }

    span {
      background: #ffffff;
      padding: 0 18px;
      position: relative;
    }
  }

  &__note {
    color: #646464;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.05em;
    line-height: 1.4;
    text-align: center;
  }

  .slick-arrow {
    font-size: 0;
    border: none;
    background: none;
    padding: 0 10px 0 10px;
    position: absolute;
    top: 48%;
    height: 40px;
    width: 40px;
    z-index: 5;

    &::before {
      content: '';
      border-left: 4px solid #c3c3c3;
      border-bottom: 4px solid #c3c3c3;
      display: block;
      height: 35px;
      width: 35px;
      transform: rotate(45deg);
    }

    &.slick-disabled {
      opacity: .3;
    }
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
    transform: rotate(180deg);
  }

  &--dark {
    background: #121212;

    .product__slider-counter {
      color: #646464;
    }
  }

  &--strap {
    img {
      object-fit: cover !important;
    }
  }

  @media (--tablet-up) {

    &__slider-counter {
      bottom: 8%;
    }

    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }
  }

  @media (--tablet-down) {
    .title {
      position: absolute;
      top: 30px;
      width: 100%;
      z-index: 1;
      margin: 0;
    }

    &__slide,
    &__bg {
      div {
        position: relative;
        max-width: 500px;
        margin: 0 auto;

        &::after {
          content: '';
          display: block;
          padding-bottom: 132%;
        }
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__bg {
      img {
        object-fit: cover;
      }
    }

    &__info {
      margin: 0 auto;
      max-width: 500px;
    }

    .slick-arrow {
      transform: scale(.7);
    }

    .slick-prev {

    }

    .slick-next {
      transform: rotate(180deg) scale(.7);
    }

    &__inner {
      padding-bottom: 12px;
    }

    &--dark {
      .title {
        color: #fff;
      }
    }

    &--strap {
      .product__inner {
        padding-top: 50px;
      }
    }

    @media (min-width: 550px) {
      &__bg div {
        &::after {
          padding-bottom: 100%;
        }
      }
    }
  }

  @media (--mobile) {
    &__inner {
      padding-bottom: 0;
    }

    &--strap {
      .product__bg {
        padding-top: 20px;

        div {
          &::after {
            padding-bottom: 95%;
          }
        }
      }
    }
  }

  @media (--tablet) {
    &__inner {
      padding-bottom: 30px;
    }

    &__info {
      max-width: 600px;
    }
  }

  @media (--laptop-up) {
    &__slider,
    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      bottom: 0;
    }

    &__bg {
      align-items: center;
      display: flex;

      div {
        width: 100%;
      }
    }

    &__slider-inner {
      &:not(.slick-initialized) {
        height: 100%;
      }
    }

    &__slide {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      img {
        max-width: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__info-wrapper {
      padding: 40px 0 60px 42%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 440px;
      padding: 34px 15px 20px;
    }

    .title,
    .product-details {
      order: -1;
    }

    .title {
      margin-bottom: 12px;
    }

    &__purchase-block {
      margin-bottom: 0;
      padding-top: 10px;

      a {
        margin-bottom: 14px;
      }
    }

    &__price {
      span {
        padding: 0 15px;
      }
    }

    .slick-prev {
      left: 40px;
    }

    .slick-next {
      right: 40px;
    }

    &--strap {
      .product__info {
        min-height: 400px;
      }
    }
  }

  @media (--desktop-up) {
    &__info-wrapper {
      padding: 75px 0 85px;
    }

    &__slider {
      width: 100%;
    }

    &__bg {
      display: flex;
      justify-content: space-between;
      width: 100%;

      div {
        width: 33.33%;

        img {
          object-fit: cover;
        }
      }
    }

    &__slider-inner {
      margin: 0 -12vw;

      &:not(.slick-initialized) {
        display: flex;
        justify-content: space-between;

        > div {
          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    &__slider-counter {
      bottom: 32px;
    }

    &__slide {
      margin: 0 12vw;
      width: 38vw !important;
    }

    .slick-prev {
      left: calc(12vw + 30px);
    }

    .slick-next {
      right: calc(12vw + 30px);
    }
  }

  @media (min-width: 1350px) {
    &__slider-inner {
      margin: 0 -8vw;
    }

    &__slide {
      margin: 0 8vw;
      width: 42vw !important;
    }

    .slick-prev {
      left: calc(8vw + 48px);
    }

    .slick-next {
      right: calc(8vw + 48px);
    }
  }

  @media (--hd) {
    &__inner {
      max-width: 1600px;
      margin: 0 auto;
    }

    &__slider-inner {
      margin: 0 -128px;
    }

    &__slide {
      margin: 0 128px;
      width: 672px !important;
    }

    .slick-prev {
      left: 158px;
    }

    .slick-next {
      right: 158px;
    }
  }
}

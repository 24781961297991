.not-found {
  background: #121212 url('/assets/images/404.jpg') 50% 50% / cover;
  color: #ffffff;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 100px 0 180px;
  }

  &__title {
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0.15em;
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  &__text {
    color: rgba(255, 255, 255, .8);
    text-align: center;
    margin-bottom: 20px;
    max-width: 580px;
  }

  .btn {
    text-transform: none;
    color: rgba(255, 255, 255, .8);
    min-width: 210px;
  }

  @media (--tablet-up) {
    &__title {
      font-size: 34px;
    }
  }

  @media (--desktop-up) {
    &__title {
      font-size: 42px;
    }
  }
}

.drop {
  background: #f3f3f3;
  color: #1c1c1c;
  opacity: 0;
  width: 100vw;
  left: calc(50% - 50vw);
  pointer-events: none;
  position: absolute;
  top: 40px;
  transition: opacity .2s .6s ease, visibility 0s .8s;
  visibility: hidden;

  &::before {
    background: #000;
    content: '';
    height: 64px;
    width: 100%;
    pointer-events: none;
    position: absolute;
    top: -40px;
    z-index: -1;
  }

  &__wrapper {
    opacity: 0;
    max-height: 0;
    transition: max-height .3s .3s ease-in, opacity .3s .1s ease;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 740px;
    margin: 0 auto;
    padding: 84px 0 65px;
  }

  &__menu {
    flex-grow: 1;
  }

  &__image {
    flex-grow: 3;
    max-width: 475px;
    margin-top: 8px;
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 60%;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      opacity: 0;
      transition: opacity .3s ease;

      &.visible {
        opacity: 1;
      }
    }
  }

  ul {
    display: block;
  }
}

.expanded .drop {
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
  transition: all .2s 0s ease, visibility 0s 0s;
  visibility: visible;

  .drop__wrapper {
    max-height: 500px;
    opacity: 1;
    transition: max-height .3s .2s ease-out, opacity .3s .3s ease;
  }
}

.scrolled .drop {
  top: 34px;
}

.order {
  background: #ffffff;
  position: relative;

  &__head,
  &__body {
    > * {
      border-bottom: 1px solid rgba(155, 155, 155, .2);
    }

    > div {
      display: block;
      letter-spacing: 0;
      padding: 16px 15px;
    }

    > ul {
      padding: 0 15px;
    }

    a {
      display: inline-block;
      position: relative;

      &::after {
        background: currentColor;
        content: '';
        height: 1px;
        opacity: .2;
        position: absolute;
        right: 1px;
        left: 1px;
        bottom: 0;
        transition: all .2s ease;
      }

      &:hover {
        text-decoration: none;

        &:after {
          opacity: .5;
        }
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 50px;
    height: 77px;
    padding: 17px 15px 25px !important;
    text-align: center;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 12px 0 12px;

    &__image {
      width: 105px;
      height: 105px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -20px;

      img {
        max-width: 100%;
      }
    }

    &__title {
      font-size: 16px;
      line-height: 1.22;
      letter-spacing: 0;
      margin-right: auto;
      padding-top: 12px;

      div {
        margin-bottom: 10px;
      }
    }

    &__remove {
      color: rgba(151, 151, 151, .8);
      font-size: 16px;
      letter-spacing: 0;
      padding-left: 12px;
      position: relative;

      &::before,
      &::after {
        background: currentColor;
        content: '';
        position: absolute;
        height: 1px;
        width: 10px;
        transform-origin: 50% 50%;
        left: 0;
        top: calc(50% - 1px);
        transform: rotate(-45deg);
        opacity: 1;
      }

      &::after {
        transform: rotate(45deg);
      }
    }

    &__cost {
      color: #9c8265;
      flex-shrink: 0;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0;
      margin-left: 10px;
    }

    &--strap {
      .item__image img {
        transform-origin: 50% 50%;
        transform: rotate(-90deg);
      }
    }
  }

  &__summary,
  &__total {
    background: #f8f8f8;
  }

  &__subtitle,
  &__total {
    font-weight: 500;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  .order__summary {
    padding-top: 20px;

    &-price {
      font-family: 'Montserrat', sans-serif;
    }
  }

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 8px;
  }

  .order__total {
    padding: 22px 15px 14px;

    &-price {
      font-family: 'Montserrat', sans-serif;
    }
  }

  .discount-enter__input,
  .discount-enter__applied {
    padding: 0 25px;
  }

  &__loader {
    align-items: center;
    background: rgba(221, 215, 210, .7);
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;

    svg {
      height: 40px;
      width: 40px;
      transform: scale(1.1);
      transition: transform .5s ease;

      .minute-hand {
        transform-origin: 32px 33px;
        animation: rotate 3s linear infinite;
      }
    }

    use {
      height: 100%;
      width: 100%;
    }
  }

  &.load {
    .order__loader {
      opacity: 1;
      visibility: visible;

      svg {
        transform: scale(1);
      }
    }
  }

  @media (--tablet-down) {
    &__head {
      position: relative;
      cursor: pointer;

      svg {
        position: absolute;
        top: calc(50% - 8px);
        right: 10px;
        width: 16px;
        height: 16px;
        transition: all .2s ease;
      }
    }

    &__body {
      max-height: 0;
      visibility: hidden;

      > * {
        opacity: 0;
      }
    }

    &__title-mobile div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 20px 0 0;

      span:first-child {
        font-size: 16px;
        font-weight: 300;
      }

      span:last-child {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &.shown {
      .order__body {
        max-height: 600px;
        visibility: visible;

        > * {
          opacity: 1;
        }
      }

      .order__head {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    .item {
      &__image {
        width: 80px;
        height: 80px;
        margin-left: -15px;
      }
    }
  }

  @media (--tablet-up) {
    &__head,
    &__body {
      > div {
        padding: 16px 25px;
      }

      > ul {
        padding: 0 25px;
      }
    }

    .order__total {
      padding: 22px 25px 14px;
    }
  }
}

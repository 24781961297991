.slide-up {
  &.appearance {
    transform: translateY(80px);
    opacity: 0;
    transition: all .5s ease-out;

    &-enter {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.style-selector {
  margin: 0 12px;

  &__image {
    background: #e6e6e6;
    border: 2px solid transparent;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-bottom: 6px;
    overflow: hidden;
    transition: all .2s ease;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    text-align: center;
    transition: all .2s ease;
  }

  &:hover,
  &.active {

    .style-selector__image {
      border-color :#cab49b;
    }

    .style-selector__title {
      color: #ad9376;
    }
  }

  a:hover {
    text-decoration: none;
  }

  &--large {
    margin: 0 10px;

    .style-selector__image {
      margin-bottom: 8px;
      height: 62px;
      width: 62px;
    }
  }

  @media (--tablet-down) {
    &__image {
      height: 60px;
      width: 60px;
    }
  }
}

.footer-payments {
  display: flex;
  flex-wrap: wrap;

  li {
    background: #fff url('/assets/images/payments.jpg') no-repeat 50% 0 / auto 600%;
    border-radius: 1px;
    flex-shrink: 0;
    height: 22px;
    width: 36px;
    margin-bottom: 6px;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.mastercard {
      background-position-y: 60%;
    }

    &.american {
      background-position-y: 0;
    }

    &.applepay {
      background-position-y: 20%;
    }

    &.gpay {
      background-position-y: 40%;
    }

    &.paypal {
      background-position-y: 80%;
    }

    &.visa {
      background-position-y: 100%;
    }
  }

  @media (--mobile) {
    justify-content: center;
    margin: 0 -2px;

    li {
      margin: 0 2px 6px;
      height: 26px;
      width: 40px;
    }
  }
}

.close {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;

  &::before,
  &::after {
    background: #1e1c1c;
    content: '';
    position: absolute;
    width: 22px;
    height: 1px;
    transform-origin: 50% 50%;
    transition: all .3s .3s ease;
    opacity: 0;
    left: calc(50% - 11px);
    top: 50%;
  }
}

.shown .close {
  &::before,
  &::after {
    opacity: 1;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}

.field {
  margin-bottom: 16px;

  .label {
    display: block;
    margin-bottom: 4px;
  }

  input,
  textarea {
    background: transparent;
    border: 1px solid #979797;
    color: #979797;
    width: 100%;
    resize: none;
    padding: 9px 16px 7px;

    &:focus {
      outline: none;
      border-color: #ddd;
    }
  }

  textarea {
    min-height: 70px;
  }

  &--submit {
    text-align: right;
  }

  &.error {
    label {
      color: red;
    }

    input,
    textarea {
      border-color: red;
    }
  }

  &__description {
    color: #a0a0a0;
    display: block;
    font-size: 13px;
    font-style: normal;
    margin-top: 4px;
  }
}

.address-fields {
  width: 100%;
}

@media (--tablet-up) {
  .field {
    width: calc(33.33% - 8px);
    margin: 0 4px 16px;

    &--half {
      width: calc(50% - 8px);
    }

    &--wide,
    &--submit {
      width: calc(100% - 8px);
    }

    &--submit {
      padding-top: 8px;
    }

    &--flexible {
      flex-grow: 1;
      min-width: calc(33.33% - 8px);
      max-width: calc(50% - 8px);
      width: auto;
    }
  }

  .address-fields {
    display: flex;

    .field:first-child {
      flex-grow: 1;
      width: auto;
    }

    .field:last-child {
      width: 150px;
    }
  }
}

@media (--desktop-up) {
  .form {
    margin-right: 50px;
    max-width: 738px;
  }
}

.watches {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .watch {
    background: no-repeat 50% 100% / contain;
    position: absolute;
    bottom: 0;
    height: 174px;
    width: 300px;

    &--center {
      left: calc(50% - 150px);
    }

    &--1 {
      &.watch--left {
        left: calc(50% - 270px);
      }

      &.watch--right {
        right: calc(50% - 270px);
      }
    }

    &--2 {
      &.watch--left {
        left: calc(50% - 376px);
      }

      &.watch--right {
        right: calc(50% - 376px);
      }
    }

    &--3 {
      &.watch--left {
        left: calc(50% - 475px);
      }

      &.watch--right {
        right: calc(50% - 475px);
      }
    }
  }

  @media (--laptop-up) {
    .watch {
      width: 338px;
      height: 197px;

      &--center {
        left: calc(50% - 169px);
      }

      &--1 {
        &.watch--left {
          left: calc(50% - 310px);
        }

        &.watch--right {
          right: calc(50% - 310px);
        }
      }

      &--2 {
        &.watch--left {
          left: calc(50% - 435px);
        }

        &.watch--right {
          right: calc(50% - 435px);
        }
      }

      &--3 {
        &.watch--left {
          left: calc(50% - 550px);
        }

        &.watch--right {
          right: calc(50% - 550px);
        }
      }
    }
  }

  @media (--desktop-up) {
    .watch {
      width: 420px;
      height: 240px;

      &--center {
        left: calc(50% - 210px);
      }

      &--1 {
        &.watch--left {
          left: calc(50% - 28.5vw);
        }

        &.watch--right {
          right: calc(50% - 28.5vw);
        }
      }

      &--2 {
        &.watch--left {
          left: calc(50% - 40vw);
        }

        &.watch--right {
          right: calc(50% - 40vw);
        }
      }

      &--3 {
        &.watch--left {
          left: calc(50% - 51vw);
        }

        &.watch--right {
          right: calc(50% - 51vw);
        }
      }
    }
  }

  @media (min-width: 1360px) {
    .watch {
      width: 450px;
      height: 263px;

      &--center {
        left: calc(50% - 225px);
      }

      &--1 {
        &.watch--left {
          left: calc(50% - 29vw);
        }

        &.watch--right {
          right: calc(50% - 29vw);
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .watch {
      &--1 {
        &.watch--left {
          left: calc(50% - 416px);
        }

        &.watch--right {
          right: calc(50% - 416px);
        }
      }

      &--2 {
        &.watch--left {
          left: calc(50% - 582px);
        }

        &.watch--right {
          right: calc(50% - 582px);
        }
      }

      &--3 {
        &.watch--left {
          left: calc(50% - 736px);
        }

        &.watch--right {
          right: calc(50% - 736px);
        }
      }
    }
  }
}

.appearance {
  .watch {
    transition: all 2.5s ease-out;

    &--1 {
      &.watch--left {
        transform: translateX(2vw);
      }

      &.watch--right {
        transform: translateX(-2vw);
      }
    }

    &--2 {
      &.watch--left {
        transform: translateX(3vw);
      }

      &.watch--right {
        transform: translateX(-3vw);
      }
    }

    &--3 {
      &.watch--left {
        transform: translateX(5vw);
      }

      &.watch--right {
        transform: translateX(-5vw);
      }
    }
  }

  &-enter {
    .watch {
      transform: translateX(0) !important;
    }
  }
}

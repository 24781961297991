.checkout-form {
  padding-bottom: 1px;

  input,
  .select {
    border-color: #e1e1e1;
    color: #000;

    &:focus {
      border-color: currentColor;
      outline: none;
    }
  }

  &__block {
    padding: 16px 15px 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(155, 155, 155, .2);
      padding-bottom: 6px;
    }
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    //padding: 16px 0 6px;
  }

  label {
    letter-spacing: 0;

    &[for=sameAddress] {
      font-style: italic;
    }
  }

  .field {
    width: calc(100% - 16px);
    margin: 0 8px 18px;

    button {
      &[type=submit] {
        height: 50px;
        text-transform: uppercase;
        width: 100%;

        i {
          display: inline-block;
          line-height: 25px;
          background: url('/assets/images/security-locks.png') no-repeat -20px 50% / auto 20px;
          height: 20px;
          width: 20px;
          margin: 0 0 -4px 5px;
        }
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 0;

      label {
        flex-grow: 1;
        padding-left: 30px;
        position: relative;

        &::before {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          border: 1px solid #e1e1e1;
          content: '';
          position: absolute;
          left: 0;
          top: calc(50% - 7px);
        }

        .checkmark {
          background: currentColor;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          top: calc(50% - 4px);
          left: 3px;
          position: absolute;
          opacity: 0;
        }

      }
    }

    &__body {
      max-height: 0;
      opacity: 0;
      visibility: hidden;

      > div {
        background: #f8f8f8;
      }
    }

    &__error {
      display: none;
      font-style: italic;
    }

    &--flex {
      display: flex;
      justify-content: space-between;

      .radio::before {
        top: 3px;
      }

      .checkmark {
        top: 7px;
      }
    }

    &--drop {
      border-bottom: 1px solid rgba(155, 155, 155, .2);
      margin: 0;

      > input[type=radio] {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        display: none;
      }

      input:checked {
        &+.field__head {
          font-weight: 500;

          .checkmark {
            opacity: 1;
          }
        }

        &~.field__body {
          max-height: 500px;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &.error {
      color: #d46054;

      input {
        border-color: #d46054;
        margin-bottom: 10px;
      }

      .country-select {
        margin-bottom: 10px;
      }

      .field__error {
        display: block;
      }
    }
  }

  &__note {
    color: #afafaf;
    letter-spacing: 0;
    font-style: italic;
    padding: 20px;

    a {
      color: #1c1c1c;
    }
  }

  &__reference {
    color: #a0a0a0;
    display: block;
    font-style: italic;
    padding-top: 6px;
  }

  .discount-enter {
    padding-bottom: 5px;
  }

  @media (--tablet-up) {
    &__block {
      padding: 16px 33px 6px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .field {
      width: calc(33.33% - 16px);

      &__body {
        padding-left: 30px;
      }

      &--half {
        width: calc(50% - 16px);
      }

      &--wide {
        width: calc(100% - 16px);
      }

      &--flexible {
        min-width: calc(33.33% - 18px);
        max-width: calc(50% - 18px);
      }
    }
  }

  &__billing-address-checkbox {
    margin-top: 7px;
  }
}

.player {
  background: #000;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s ease 0.3s;
}
.player__iframe {
  height: 100%;
  width: 100%;
}
.player iframe {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease;
}
.player__close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  font-size: 0;
  line-height: 0;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50px;
  height: 50px;
}
.player__close b {
  display: block;
  margin: 10px auto 0;
  position: relative;
  width: 30px;
  height: 30px;
}
.player__close b:before, .player__close b:after {
  position: absolute;
  top: 14px;
  left: 6px;
  background: #fff;
  content: '';
  height: 2px;
  width: 18px;
  transform: rotate(-45deg);
}
.player__close b:after {
  transform: rotate(45deg);
}
.player__close:before {
  background: rgba(19, 21, 44, 0.8);
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  content: '';
}
.player--opened {
  visibility: visible;
  opacity: 1;
  transform: translateZ(0) !important;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.player--opened iframe {
  opacity: 1;
  transition: opacity 0.3s ease 0.4s;
}
@media (--tablet-up) {
  .player {
    transform: scaleY(0.0001) translateZ(0);
  }
  .player__close {
    top: 10px;
    right: 10px;
  }
  .player__close b {
    transition: transform 0.2s ease, opacity 0.2s ease;
  }
  .player__close:hover b {
    transform: scale(1.4);
  }
}

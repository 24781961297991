.articles {
  padding: 54px 0 38px;
  position: relative;
  overflow: hidden;

  .slick-list {
    flex-grow: 1;
  }

  .item {
    margin-bottom: 40px;
    padding-bottom: 20px;
    position: relative;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
    }

    &__image {
      background: #cab49b;
      position: relative;
      margin-bottom: 30px;
      max-width: 476px;
      overflow: hidden;
      width: 100%;

      img {
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 5s ease;
      }

      &::after {
        content: '';
        display: block;
        padding-bottom: 97%;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &__title {
      font-size: 21px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: 0.1em;
      text-decoration: none;
      text-transform: uppercase;
      margin-bottom: 18px;
      transition: all .3s ease;

      &:hover {
        text-decoration: none;
        opacity: .6;
      }

      a {
        text-decoration: inherit;
      }
    }

    p {
      opacity: .7;
      margin-bottom: 12px;
    }

    &__title,
    p {
      max-width: 390px;
    }

    &__link {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  &__dots {
    padding-top: 34px;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    margin: 0 20px;

    &:focus {
      outline: none;
    }
  }

  .slick-list {
    margin: 0 -20px;
    width: auto;
  }

  .slick-dots {
    display: flex;
    justify-content: center;

    li {
      margin: 0 9px;
    }

    button {
      background: #000;
      font-size: 0;
      border: none;
      height: 2px;
      width: 86px;
      opacity: 0.2;
      transition: all .1s ease;
    }

    .slick-active {
      button {
        opacity: 1;
      }
    }
  }

  &--small-padding {
    padding-top: 5px;
  }

  @media (--mobile) {
    &__items {
      margin: 0 -5px;
    }

    .item {
      p {
        padding: 0 18px;
      }
    }
  }

  @media (--tablet-up) {
    &__items {
      display: flex;
      justify-content: space-between;
    }

    .item {
      flex-grow: 1;
      padding: 0 20px 20px;
      width: 50%;

      &__image {
        margin-bottom: 46px;

        &:focus {
          outline: none;
        }

        &::after {
          padding-bottom: 60%;
        }
      }

      &__title {
        font-size: 24px;
        margin-bottom: 23px;
      }

      p {
        margin-bottom: 23px;
      }
    }
  }

  @media (--laptop-up) {
    padding: 76px 0 50px;

    &__drawing {
      background: url('../images/drawing-03.png') no-repeat 100% 50% / contain;
      position: absolute;
      width: 254px;
      height: 214px;
      right: 0;
      top: 18%;
    }

    &__dots {
      display: none;
    }

    &--small-padding {
      padding-top: 5px;
    }
  }

  @media (--desktop-up) {
    padding-bottom: 86px;
  }
}

.head {
  background: #b8a997;
  color: #f9f7f5;
  padding: 85px 0 55px;
  position: relative;
  overflow: hidden;

  .center {
    flex-grow: 1;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    div {
      background: no-repeat 50% 50% / cover;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .head__watch-half {
      background: url('/assets/images/watch-half.png') no-repeat 54% 100% / 70vw auto;
      position: absolute;
      top: 0;
      left: 0;

      @media (--mobile) {
        background-size: 350px auto;
      }

      @media (max-width: 375px) {
        background-position: 70% 100%;
        background-size: 255px auto;
      }

      @media (--tablet-up) {
        background-size: 400px auto;
      }

      @media (--laptop-up) {
        background-size: 490px auto;
      }
    }
  }

  &__content {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
  }

  &__title {
    font: 300 30px/1.24 'Neutra Text', sans-serif;
    letter-spacing: 0.15em;
    margin-bottom: 10px;
    text-transform: uppercase;

    &.capitalized {
      text-transform: capitalize;
    }
  }

  &__text {
    margin-bottom: 20px;
    max-width: 450px;
  }

  &__quote {
    display: none;
  }

  .btn {
    margin-bottom: 10px;
  }

  &--lookbook {
    padding: 0;

    .head__content {
      min-height: 100vh !important;
    }
  }

  &--simple {
    justify-content: center;
    background: #131313;
  }

  &--main {
    padding: 130px 0 0;

    .head__text {
      max-width: 720px;
    }
  }

  @media (--mobile) {
    &__content {
      min-height: 360px;
      justify-content: center;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 26px;
    }

    &__text {
      display: none;
    }

    .btn {
      margin-bottom: 14px;
    }

    &--main {
      padding: 0;

      .head__content {
        min-height: 550px;
        padding: 80px 0 175px;
        justify-content: center;
      }
    }

    &--lookbook {
      .head__content {
        padding-top: 55px;
      }
    }

    &--xmas {
      min-height: 115vw;
      padding: 40px 0 50px;
    }
  }

  @media (max-width: 375px) {
    &--main {
      .head__content {
        min-height: 450px;
        padding-bottom: 126px;
      }
    }
  }

  @media (--tablet-down) {
    &__title {
      font-size: 34px;
    }
  }

  @media (--tablet-up) {
    padding-top: 250px;

    &__title {
      font-size: 42px;
    }

    &__text {
      margin-bottom: 30px;
    }

    &--main {
      .head__content {
        padding-bottom: 246px;
      }
    }

    &--lookbook {
      padding: 0;

      .head__content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 235px 0 200px;
      }
    }

    &--simple {
      padding: 0;

      .head__content {
        padding-top: 80px;
        min-height: 43vw;
        justify-content: center;
      }
    } 

    &--xmas {
      min-height: 78vw;
      padding: 18vw 0 50px;
    }
  }

  @media (--laptop-up) {
    &--xmas {
      display: flex;
      align-items: center;
      min-height: 47vw;
      padding: 150px 0;

      .head__content {
        align-items: flex-start;
        text-align: left;
      }

      .head__title {
        max-width: 500px;
      }
    }
  }

  @media (--desktop-up) {
    &__quote {
      display: block;
      position: absolute;
      right: 45px;
      bottom: 11%;
      font-size: 24px;
      letter-spacing: 0.05em;
      line-height: 32px;
      font-weight: 300;
      max-width: 410px;
    }

    &--simple {
      .head__content {
        min-height: 30vw;
      }
    }
  }

  @media (min-width: 1440px) {
    &--simple {
      .head__content {
        min-height: 30vw;
      }
    }
  }

  @media (--hd) {
    .head__content {
      min-height: 350px;
    }

    &--simple {
      .head__content {
        min-height: 30vw;
      }
    }
  }
}

.intro {
  padding: 32px 0 40px;

  &__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  p {
    max-width: 500px;
    opacity: .8;
  }

  @media (--mobile) {
    .title {
      font-size: 27px;
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      max-width: 280px;
    }
  }

  @media (--laptop-up) {
    padding: 32px 0 70px;

    &__inner {
      .subtitle {
        margin-bottom: 18px;
      }

      .title {
        margin-bottom: 14px;
      }
    }

    &--small-margin {
      padding-bottom: 46px;
    }
  }

  @media (--desktop-up) {
    padding-bottom: 80px;

    &--small-margin {
      padding-bottom: 46px;
    }
  }
}

.relevant {
  border-bottom: 1px solid rgba(197,197,197,.5);
  border-top: 1px solid rgba(197,197,197,.5);

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 21px 0 17px;
    transition: all .3s ease;

    span {
      width: 15px;
      height: 15px;
      opacity: .5;
      transition: .3s ease;

      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid currentColor;
        border-right: 1px solid currentColor;
        transform: rotate(45deg);
        transform-origin: 50% 50%
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 15px;
  }

  &__items {
    opacity: 0;
    max-height: 0;
    transform: scaleY(0);
    visibility: hidden;
    transition: opacity .3s 0s ease-out, max-height .3s 0s ease-out, visibility 0s .3s, transform 0s .3s;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 0 12px;

    &__image {
      position: relative;
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      margin-right: 5px;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: contain;
        transform: rotate(-90deg);
      }
    }

    &__info {
      letter-spacing: 0;
      padding-right: 10px;
      margin-right: auto;
    }

    &__title {
      margin-bottom: 2px;
      line-height: 1.33;
    }

    &__price {
      font-size: 16px;
      color: #9c8265;
      font-weight: 500;
    }

    a {
      border: 1px solid #c0a98f;
      font-style: italic;
      flex-shrink: 0;
      letter-spacing: 0;
      padding: 6px 12px 4px;
      position: relative;

      span {
        position: relative;
      }

      &::before {
        content: '';
        position: absolute;
        background: #ffffff;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        transform: scale(.5);
        transition: all .2s ease;
      }

      &:hover {
        text-decoration: none;

        &::before {
          transform: scale(1);
          opacity: .2;
        }
      }
    }

    &--strap {
      .item__image {
        position: relative;

        img {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.opened {
    border-bottom: 1px solid rgba(197,197,197,0);

    .relevant__head {
      span {
        transform: rotateX(180deg);
      }
    }

    .relevant__items {
      opacity: 1;
      max-height: 500px;
      visibility: visible;
      transform: scaleY(1);
      transition: opacity .3s 0s ease, max-height .3s 0s ease, visibility 0s 0s, transform 0s 0s;
    }
  }

  @media (max-width: 499px) {
    &__head {
      padding: 15px 0 11px;
    }

    .item {
      padding: 11px 0 10px;
    }

    .item {
      &__image {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.success-head {
  padding: 100px 0 0;
  text-align: center;

  .title {
    margin-bottom: 4px;
  }

  &__text {
    margin-bottom: 18px;
  }

  &__save {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      background: url('/assets/images/icon-pdf.png') no-repeat 50% 50% / contain;
      display: inline-block;
      margin: -1px 6px 0 0;
      width: 18px;
      height: 18px;
    }

    span {
      position: relative;
      line-height: 1.2;

      &::after {
        background: currentColor;
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  @media (--tablet-up) {
    padding-top: 148px;
  }
}

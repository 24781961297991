.snow {

  @media (--tablet-down) {
    display: none;
  }

  @media (--laptop-up) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.discount-enter {
  position: relative;
  min-height: 31px;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  a {
    font-style: italic;
    position: relative;
    padding-right: 12px;

    svg {
      width: 14px;
      height: 14px;
      position: absolute;
      right: -3px;
      top: calc(50% - 7px);
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__input,
  &__applied,
  &__error {
    background: #fff;
    transition: opacity .4s ease, visibility 0s .4s;
  }

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-bottom: 8px;
    visibility: hidden;
  }

  &__applied,
  &__error {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    font-style: italic;
    justify-content: space-between;
    opacity: 0;
    padding-bottom: 8px;
    visibility: hidden;

    span {
      color: #9c8265;
      font-style: normal;
      text-transform: uppercase;
    }

    a {
      color: #a0a0a0;
    }
  }

  &__error {
    div {
      color: #d46054;
    }
  }

  button {
    border: none;
    background: #cab49b;
    color: #ffffff;
    line-height: 20px;
    padding: 5px 10px 4px;
    min-width: 0;
    height: auto;
    text-transform: uppercase;
  }

  input {
    background: transparent;
    border: none;
    flex-grow: 1;
    margin-right: 20px;

    &::placeholder {
      font-weight: 300;
      opacity: .6;
    }

    &:focus {
      outline: none;
    }
  }

  &.active {
    .discount-enter__main {
      opacity: 0;
      visibility: hidden;
    }

    .discount-enter__input {
      opacity: 1;
      visibility: visible;
      transition: opacity .4s ease, visibility 0s 0s;
    }
  }

  &.applied,
  &.error,
  &.active {
    .discount-enter__main {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.applied,
  &.error {
    .discount-enter__input {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.applied {
    .discount-enter__applied {
      opacity: 1;
      visibility: visible;
      transition: opacity .4s ease, visibility 0s 0s;
    }
  }

  &.error {
    .discount-enter__error {
      opacity: 1;
      visibility: visible;
      transition: opacity .4s ease, visibility 0s 0s;
    }
  }
}

.order {
  .discount-enter {
    min-height: 57px;

    &__applied,
    &__error {
      padding: 0 25px;
    }
  }
}

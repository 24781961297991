@media (--laptop-up) {
  .no--sticky {
    position: absolute !important;
  }
}
.header {
  color: #fff;
  font-size: 14px;
  position: fixed;
  transition: transform .3s ease, background-color .3s ease;
  width: 100%;
  z-index: 10;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    transition: transform .3s ease;
  }

  &__logo {
    z-index: 21;
  }


  .logo {
    background: url("/assets/svg/logo-new.svg") no-repeat 50% 50% / contain;
    display: block;
    width: 200px;
    height: 30px;
    font-size: 0;

    &:hover,
    a:hover {
      text-decoration: none;
    }

    @media (min-width: 370px) {
      width: 240px;
    }

    @media (min-width: 520px) {
      width: 268px;
    }
  }

  .item {
    &__head {
      font-family: "Neutra Text TF", sans-serif;

      * {
        z-index: 10;
      }
    }
  }

  a:hover {
    text-decoration: none;
  }

  .arrow {
    fill: currentColor;
    height: 15px;
    margin-bottom: 2px;
    opacity: .5;
    width: 15px;
    margin-left: 5px;
  }

  .nav {
    display: flex;
    transition: opacity .5s ease;

    .item {
      margin: 0 25px;

      &__head {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .1em;

        span {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            bottom: 0;
            background: currentColor;
            height: 1px;
            left: 0;
            transform-origin: 0 50%;
            transform: scaleX(0);
            transition: all .2s ease-in;
            width: 100%;
          }
        }
      }

      &:hover {
        .item__head {
          span::after {
            transform: scaleX(1);
            transition: all .2s ease-out;
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
      transition: opacity .5s ease;
    }
  }

  .divider {
    background: #fff;
    opacity: .3;
    width: 1px;
    height: 17px;
    margin: 0 17px 0 20px;
  }

  &__cart-btn {
    cursor: pointer;
    display: flex;
    font-family: "Neutra Text TF", sans-serif;
    align-items: center;
    text-transform: uppercase;

    span:not(.header__cart-count) {
      padding-top: 3px;
    }
  }

  &__cart-count {
    background: #ffffff;
    border-radius: 50%;
    color: #1c1c1c;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    height: 17px;
    width: 17px;
    margin-left: 6px;
    letter-spacing: 0;
    line-height: 10px;
    text-align: center;
    padding: 4px 0 3px;
  }

  &__cart-icon,
  .lang__icon {
    display: inline-block;
    background: no-repeat 50% 0% / 100%;
    height: 18px;
    width: 18px;
  }

  &__cart-icon {
    background-image: url('/assets/images/cart.png');
  }

  .lang {
    position: relative;
    width: 25px;
    height: 25px;

    &__icon {
      background-image: url('/assets/images/globe.png');
      display: block;
      cursor: pointer;
      margin: 3px auto 0;
    }

    &__menu {
      position: absolute;
      right: 0;
      top: 100%;
      opacity: 0;
      padding-top: 15px;
      visibility: hidden;
      max-height: 0;
      transition: visibility 0s .3s, opacity .3s ease, max-height .3s ease;
      white-space: nowrap;
    }

    &:hover {
      .lang__menu {
        opacity: 1;
        visibility: visible;
        max-height: 200px;
        transition: visibility 0s 0s, opacity .3s ease, max-height .3s ease;
      }
    }

    ul {
      color: #1c1c1c;
      background: #ffffff;
      padding: 18px 40px 18px 20px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    a {
      position: relative;
      padding-left: 22px;
      transition: all .2s ease;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 7px);
      }

      &::before {
        border: 1px solid #d7d7d7;
        border-radius: 50%;
        height: 13px;
        width: 13px;
      }

      &::after {
        background: #000;
        border-radius: 50%;
        height: 7px;
        width: 7px;
        opacity: 0;
        left: 3px;
        transition: all .2s ease;
      }

      &:hover {
        text-decoration: none;
        color: #6d6d6d;
      }
    }

    .checked {
      a::after {
        opacity: 1;
        top: calc(50% - 4px);
      }
    }
  }

  &.scrolled {
    background-color: #000;
    transform: translateY(-20px);

    .header__inner {
      transform: translateY(10px);
    }
  }

  &--dark,
  &.scrolled {
    background: #000;

    .header__cart-icon {
      background-position-y: 100%;
    }

    .arrow {
      fill: #9c8265;
    }
  }

  &--checkout {
    .header__inner {
      justify-content: space-between;
    }

    .header__left,
    .header__right {
      flex-grow: 2;
      display: flex;
      max-width: 33.33%;
    }

    .header__right {
      justify-content: flex-end;
    }

    .logo {
      padding: 4px 0 0 !important;
      margin: 0 auto;
      flex-grow: 0 !important;
    }

    .back-btn {
      color: #a2a2a2;
      letter-spacing: 0;
      padding-left: 15px;
      position: relative;

      svg {
        fill: currentColor;
        position: absolute;
        left: 0;
        transform: rotate(90deg);
        transform-origin: 50% 50%;
        height: 16px;
        width: 16px;
        top: calc(50% - 9px);
      }

      &:hover {
        text-decoration: none;
      }
    }

    .lang__menu {
      padding-top: 23px;
    }

    &.scrolled {
      .lang__menu {
        transform: translateY(-10px);
      }
    }
  }

  @media (--tablet-down) {
    .mobile-btn {
      height: 24px;
      padding: 11px 0;
      width: 24px;

      &::before {
        transform: translateY(-7px);
      }

      &::after {
        transform: translateY(7px);
      }
    }

    &__cart-icon {
      height: 24px;
      width: 24px;
    }

    &__right {
      min-width: 49px;
    }

    &__cart-count {
      height: 20px;
      width: 20px;
      padding: 5px 0;
    }
  }

  @media (--mobile) {
    &--checkout {

      .header__left,
      .header__right {
        max-width: 30px;
      }

      .back-btn {
        padding: 0;

        span {
          display: none;
        }

        svg {
          position: static;
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  @media (--laptop-up) {
    &__inner {
      height: 68px;
    }

    &__logo,
    &__right {
      flex-grow: 1;
      z-index: 10;
    }

    &__logo {
      padding-left: 15px;
    }

    &__cart-icon {
      margin-right: 4px;
    }

    &--checkout {
      .header__inner {
        height: 65px;
      }
    }
  }

  @media (--desktop-up) {
    .nav {
      margin-right: 20px;

      .item {
        margin: 0 27px;
      }
    }
  }

  @media (min-width:1400px) {
    .nav {
      .item {
        margin: 0 35px;
      }
    }
  }

  @media (max-width: 1200px) {
    .hide-1200 { display: none; }
  }
}

.menu-shown .header {
  background: transparent;

  &__right > div:not(.menu-btn),
  .nav {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-out, visibility 0s .3s;
  }
}

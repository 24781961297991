.strap-conf {
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  padding: 35px 0 80px;
  position: fixed;
  top: 0;
  transform: scale(1.1);
  transition: transform .3s ease, opacity .3s ease, visibility 0s .3s, z-index 0s .3s;
  height: 100%;
  width: 100vw;
  z-index: -1;

  &.shown {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: transform .3s ease, opacity .3s ease, visibility 0s 0s, z-index 0s 0s;
    z-index: 10;
  }

  .center {
    height: 100%;
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__title {
    font-family: 'Neutra Text TF', sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 26px;
  }

  .title {
    margin-bottom: 7vh;
    text-align: center;
  }

  &__watch {
    max-width: 110px;
    padding-top: 10px;

    li {
      margin: 0 auto 20px;
    }

    a {
      background: no-repeat 50% 0 / 100%;
      display: block;
      width: 90px;
      height: 90px;
      margin: 0 auto;
      position: relative;
      transition: background-position 0s 0s;

      &::after {
        background: #b09d88;
        border-radius: 50%;
        content: '';
        height: 72px;
        width: 72px;
        opacity: 0;
        position: absolute;
        left: calc(50% - 36px);
        top: calc(50% - 36px);
        transform: scale(.5);
        transition: all .3s ease;
      }

      &.active {
        background-position: 50% 100%;
        transition: background-position 0s .3s;

        &::after {
          opacity: .2;
          transform: scale(1);
        }
      }
    }
  }

  &__view {
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      transition: opacity .3s ease, visibility 0s .3s ease;
    }

    div {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .visible img {
      opacity: 1;
      visibility: visible;
      transition: opacity .3s .2s ease, visibility 0s .2s ease;
    }
  }

  &__straps {
    width: 50%;
    max-width: 540px;
  }

  &__selector {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px 15px;
  }

  &__view {
    flex-grow: 1;
    max-width: 400px;
    margin: 0 auto;
  }

  .strap {
    border-bottom: 2px solid rgba(166, 142, 117, 0);
    margin: 0 12px;
    width: calc(33.33% - 24px);
    padding: 34px 12px 12px;
    cursor: pointer;
    transition: all .3s ease;

    &__image {
      margin-bottom: 14px;
    }

    &__text {
      text-align: center;
      font-style: italic;
      line-height: 1.2;
    }

    &:hover {
      color: #927351;
    }

    &.active {
      background: #efebe7;
      border-color: rgba(166, 142, 117, 1);
      color: #927351;
    }
  }

  .specs {
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 20px 0 14px;

    &__titles,
    &__values div {
      display: flex;
    }

    &__titles {
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &__values {
      color: #5f5f5e;

      div {
        display: none;

        &.visible {
          display: flex;
        }
      }
    }

    span {
      width: 25%;
    }
  }

  &__purchase {
    align-items: center;
    display: flex;
    padding-top: 24px;
  }

  &__price {
    font-size: 24px;
    letter-spacing: 0;
    margin-right: 34px;
  }

  .btn {
    min-width: 190px;
  }

  .close {
    position: absolute;
    top: 40px;
    right: 55px;
  }

  .features-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @media (min-width: 1350px) {
    .title {
      margin-bottom: 8vh;
    }
  }

  @media (--hd) {
    .title {
      margin-bottom: 10vh;
    }
  }
}

//.country-select {
//  width: 100%;
//
//  input {
//    padding-left: 36px !important;
//  }
//
//  .flag-dropdown {
//    width: 100%;
//
//    &:hover {
//      .selected-flag {
//        background: transparent !important;
//      }
//    }
//
//  }
//
//  .selected-flag {
//    width: 100% !important;
//    padding: 0 10px;
//  }
//
//  .flag {
//    background-image: url('/assets/images/flags@2x.png') !important;
//    transform: scale(.75);
//  }
//
//  .arrow {
//    border: none !important;
//    background: url('../images/select-arrow.png') no-repeat 50% 50% / contain;
//    height: 7px !important;
//    width: 10px !important;
//    right: 11px !important;
//  }
//}

.select {
  background: #fff;
  border: 1px solid #e1e1e1;
  cursor: pointer;
  display: block;
  width: 100%;
  position: relative;
  height: 40px;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 30px 6px 16px;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;

    &:focus {
      outline: none;
    }

    &:invalid,
    option[value=""] {
      opacity: .5;
      font-style: italic;
    }
  }

  option {
    &[disabled] {
      display: none;
    }
  }

  svg {
    position: absolute;
    right: 8px;
    top: calc(50% - 4px);
    width: 12px;
    height: 12px;
    pointer-events: none;
  }

  &--country {
    padding: 4px 25px 0 40px;

    .flag {
      position: absolute;
      left: 16px;
      top: calc(50% - 5px);
    }

    span {
      display: block;
      padding-top: 5px;
    }

    select {
      position: absolute;
      opacity: 0;
      //left: 0;
      //top: 0;
      //height: 40px;
    }
  }
}

@media (--desktop-up) {
  .checkout .select--country {
    border: none;
    display: inline-block;
    width: auto;
    padding: 4px 30px 0 25px;

    select {
      padding-left: 25px;

      &:focus {
        border: transparent;
        outline: none;
      }
    }

    .flag {
      left: 0;
    }
  }
}


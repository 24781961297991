.videos {
  background: #121212;
  color: #fff;
  padding: 105px 0 33px;
  min-height: 300px;

  .slick-list {
    margin: 0 auto;
    max-width: 725px;
  }

  &__items {
    margin: 0 auto;
    max-width: 1110px;
    position: relative;

    &:not(.slick-initialized) {
      .item:nth-child(n+2) {
        display: none;
      }
    }
  }

  .slick-arrow {
    background: none;
    color: inherit;
    align-items: center;
    border: none;
    display: flex;
    justify-content: center;
    font-size: 0;
    padding: 0 0 0 4px;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    width: 36px;
    height: 36px;
    opacity: .4;
    transition: opacity .2s ease;
    z-index: 1;

    &::after {
      border-left: 4px solid currentColor;
      border-bottom: 4px solid currentColor;
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      transform-origin: 50% 50%;
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 1;
    }


    @media (--tablet-up) {
      height: 50px;
      width: 50px;
      margin-top: -25px;

      &::after {
        width: 35px;
        height: 35px;
      }
    }
  }

  .slick-prev {

  }

  .slick-next {
    right: 0;
    transform: scaleX(-1);
  }

  .item {
    background: #000 no-repeat 50% 50% / cover;
    cursor: pointer;
    margin: 0 auto;
    max-width: 725px;
    min-height: 345px;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-bottom: 48.83%;
    }

    &__info {
      background: rgba(0, 0, 0, .7);
      padding: 10px 17px;
      position: absolute;

      @media (--mobile) {
        bottom: 0;
        left: 0;
        right: 0;
      }

      @media (--tablet-up) {
        bottom: 13px;
        left: 13px;
        max-width: calc(100% - 26px);
        min-width: 385px;
      }
    }

    &__title {
      font-size: 16px;
      text-transform: uppercase;
    }

    &__text {
      font-weight: 300;
    }

    &__play {
      border: 2px solid currentColor;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 50px;
      width: 50px;
      margin: -25px 0 0 -25px;
      top: 50%;
      left: 50%;
      opacity: .5;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }

      &::after {
        border-left: 20px solid currentColor;
        border-bottom: 12px solid transparent;
        border-top: 12px solid transparent;
        content: '';
        margin-left: 6px;
      }

      @media (--tablet-up) {
        border-width: 3px;
        width: 66px;
        height: 66px;
        margin: -33px 0 0 -33px;

        &::after {
          border-left-width: 27px;
          border-top-width: 15px;
          border-bottom-width: 15px;
          margin-left: 8px;
        }
      }
    }
  }

  &__nav {
    margin-top: 30px;

    &:not(.slick-initialized) {
      visibility: hidden;
    }
  }

  &__preview {
    cursor: pointer;
    font-size: 0;
    padding: 3px;
    position: relative;
    margin: 0 4px;
    width: 131px;
    height: 66px !important;

    &::before {
      background: #9c8265;
      border-radius: 2px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &.slick-current::before {
      opacity: 1;
      transition: opacity 0.3s 0.3s;
    }

    div {
      background: #000 no-repeat 50% 50% / cover;
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;

      &::after {
        border-left: 13px solid #c1bcb7;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        content: '';
        margin: auto;
      }
    }
  }
}

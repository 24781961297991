.explore {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: absolute;
  left: 174px;
  bottom: 3.5%;
  letter-spacing: .1em;
  transform-origin: 0 50%;
  transform: rotate(-90deg);

  &::before {
    background: #9c8265;
    content: '';
    display: inline-block;
    width: 35px;
    height: 1px;
    margin-right: 15px;
  }
}

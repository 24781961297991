.text {
  padding: 65px 0 4px;

  &__content {
    padding-bottom: 40px;

    &:last-child {
      padding-bottom: 20px;
    }
  }

  .title,
  p,
  &__block {
    max-width: 540px;
    margin: 0 auto 20px;
  }

  .label {
    display: block;
    letter-spacing: 0;
    margin-bottom: 5px;
  }

  .country-select {
    .arrow {
      right: 18px !important;
    }
  }

  input {
    width: 100%;
    padding: 6px;
  }

  .payment-methods {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    li {
      border: 1px solid #e7e7e7;
      border-radius: 3px;
      background: #fff no-repeat 50% 50% / contain;
      margin-right: 7px;
      width: 68px;
      height: 40px;
    }
  }

  .shipping {
    background: #ffffff;
    letter-spacing: 0;
    padding: 25px 70px 25px 25px;
    position: relative;

    &__price {
      position: absolute;
      right: 30px;
      line-height: 22px;
      top: calc(50% - 7px);
      text-transform: uppercase;
    }

    &__terms {
      color: #afafaf;
      font-style: italic;
    }
  }

  .title {
    margin-bottom: 14px;
  }

  img {
    display: block;
    max-width: calc(100% + 30px);
    margin: 0 -15px 40px;
  }

  @media (--tablet-up) {
    img {
      margin-bottom: 60px;
    }
  }

  @media (--laptop-up) {
    img {
      max-width: 866px;
      margin: 0 auto 40px;
    }
  }


  &--informational {
    padding: 52px 0 22px;

    .title {
      margin-bottom: 22px;

      &--small {
        font-size: 13px;
        font-weight: 700;
      }
    }

    p {
      color: #5e5e5e;
    }
  }

  &--returns {
    .title {
      margin-bottom: 4px;
    }

    @media (--desktop-up) {
      padding-top: 90px;
    }
  }
}

.product-promo {
  background: #000000;
  color: #fff;
  position: relative;
  overflow: hidden;

  &__bg {
    position: relative;

    div {
      background: no-repeat 50% 50% / cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    max-width: 525px;
  }

  .title {
    margin-bottom: 16px;
  }

  &__text {
    margin-bottom: 32px;
  }

  &__film-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  &__film {
    display: flex;
    align-items: center;
    font-style: italic;
    font-size: 18px;
    letter-spacing: 0;

    > div {
      padding-left: 30px;
    }
  }

  &__video {
    background: rgba(40, 40, 40, .9) no-repeat 50% 50% / cover;
    cursor: pointer;
    max-width: 190px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      display: block;
      padding-bottom: 50%;
    }

    i {
      border: 2px solid currentColor;
      border-radius: 50%;
      display: block;
      position: absolute;
      height: 50px;
      width: 50px;

      &::after {
        border-left: 20px solid currentColor;
        border-right: 20px solid transparent;
        border-bottom: 12px solid transparent;
        border-top: 12px solid transparent;
        content: '';
        display: block;
        height: 24px;
        width: 40px;
        position: absolute;
        left: calc(50% - 8px);
        top: calc(50% - 12px);
      }
    }
  }

  .line {
    margin-left: auto;
    margin-right: auto;
  }

  &__quote-person {
    font-weight: 300;
  }

  &--only-image {
    @media (--tablet-up) {
      min-height: 540px;
    }

    @media (--desktop-up) {
      min-height: 740px;
    }
  }

  &--simple {
    .product-promo__content {
      max-width: 354px;
    }
  }

  &--center {
    .product-promo__content {
      align-items: center;
      text-align: center;
      max-width: 510px;
    }

    .line {
      margin-bottom: 32px;
    }

    .title {
      margin-bottom: 22px;
    }
  }

  @media (--mobile) {
    &__bg {
      height: 330px;

      img {
        height: 125%;
        object-position: 0 50%;
      }
    }

    .title {
      text-align: center;
    }

    &__text {
      text-align: justify;
    }

    &__inner {
      position: relative;
      background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);
    }

    &__content {
      padding: 60px 0 40px;
    }

    &__film {
      display: block;

      > div {
        padding-left: 15px;
      }
    }

    &__video {
      max-width: none;
      height: 160px;
      margin: 0 auto 20px;

      &::after {
        display: none;
      }
    }
  }

  @media (--tablet-up) {
    &__bg {
      position: absolute;
      top: -20px;
      bottom: -20px;
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: auto;
      padding: 60px 20px 40px;
    }

    &--left {
      .product-promo__content {
        margin: 0 auto 0 0;
      }
    }
  }

  @media (--desktop-up) {
    &__content {
      min-height: 690px;
      padding: 95px 0 45px;
    }
  }

  @media (--hd) {
    &__content {
      min-height: 800px;
      padding: 150px 0 45px;
    }
  }

  &--simple {
    .product-promo__content {
      padding: 84px 0 45px;

      @media (--laptop-up) {
        min-height: 740px;
      }
    }
  }

  &--center {
    .product-promo__content {
      margin: 0 auto;
      padding: 40px 0 45px;
    }
  }
}

.product-details {
  padding-top: 6px;
  margin-bottom: 8px;

  &__head {
    color: #646464;
    font-family: 'Neutra Text TF', sans-serif;
    font-size: 13px;
    letter-spacing: 0.1em;
    padding: 4px 0 12px;
    text-transform: uppercase;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      cursor: pointer;
      display: inline-block;
      line-height: 2;
      position: relative;
      transition: all .2s ease;

      &:not(:last-child) {
        margin-right: 25px;
        white-space: nowrap;
      }

      &::after {
        background: currentcolor;
        content: '';
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
        height: 1px;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: all .2s ease;
      }

      &:hover {
        color: #1c1c1c;
      }
    }

    .active {
      color: #1c1c1c;
      font-weight: 500;

      &::after {
        transform: scaleX(1);
      }
    }
  }

  &__body {
    position: relative;
    transition: all .3s ease;

    &:not(.slick-initialized) {
      > div:nth-child(n+2) {
        display: none;
      }
    }
  }

  @media (--laptop-up) {
    margin-bottom: 16px;

    &__head {
      display: flex;
      justify-content: space-between;

      li {
        &::after {
          bottom: 0;
        }

        &:not(:last-child) {
          margin-right: 26px;
        }
      }
    }
  }
}

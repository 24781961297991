.hide {
  display: none !important;
}

.hide-mobile {
  @media (--mobile) {
    display: none !important;
  }
}

.hide-tablet {
  @media (--tablet) {
    display: none !important;
  }
}

.hide-laptop {
  @media (--laptop) {
    display: none !important;
  }
}

.hide-desktop {
  @media (--desktop) {
    display: none !important;
  }
}

.hide-hd {
  @media (--hd) {
    display: none !important;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s;

  &__overlay {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
  }

  &__scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
  }

  &__main {
    display: flex;
    padding: 20px;
    width: 100%;
    min-height: 100%;

    @media (--tablet-up) {
      padding: 30px;
    }
  }

  &__body {
    background: #fff;
    margin: auto;
    max-width: 100%;
    transform: scale(0.5);
    transition: transform 0.3s;
  }

  &--opened {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease;

    .popup__body {
      transform: scale(1);
    }
  }
}

.list {
  padding: 40px 0;

  &__info {
    .subtitle {
      margin-bottom: 4px;
    }

    .title {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;
      opacity: .8;
    }
  }

  &__text {
    font-size: 16px;
  }

  &__filter {
    align-items: center;
    display: flex;
    justify-content: center;
    color: #5c5c5c;
    margin-bottom: 30px;
  }


  .item {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    padding: 40px 30px 84px;
    position: relative;

    &__image {
      max-width: 250px;
      margin-bottom: 12px;
      position: relative;
      width: 100%;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: opacity .3s ease;
      }

      .secondary {
        z-index: -1;
        opacity: 0;
        transition: opacity .3s ease, z-index 0s .3s;
      }

      &:not(.single) {
        &:hover {
          img {
            opacity: 0;
          }

          .secondary {
            z-index: 0;
            opacity: 1;
            transition: opacity .3s ease, z-index 0s 0s;
          }
        }
      }
    }

    &__title {
      font-size: 22px;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      text-align: center;
      transition: all .2s ease;

      &:hover {
        text-decoration: none;
        opacity: .7;
      }

      a {
        text-decoration: inherit;
      }
    }

    &__specs {
      font-size: 16px;
      color: #979797;
      font-style: italic;
      margin-bottom: 5px;
      max-width: 200px;
      text-align: center;
    }

    &__price {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      letter-spacing: 0;
    }

    &__button {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      text-align: center;
    }

    &--strap {
      padding-top: 60px;

      .item__image {
        margin-bottom: 46px;

        &::after {
          padding-bottom: 32%;
        }
      }
    }
  }

  &--dark {
    background: #1e1c1c;

    .title,
    p {
      color: #fff;
    }

    .item {
      background: #262323;
      color: #fff;

      &__specs {
        color: #cecaca;
      }
    }
  }

  &--large-margin {
    padding-bottom: 60px;
  }

  &.show-gold {
    .item {
      &:not([data-type=gold]) {
        display: none;
      }
    }
  }

  &.show-silver {
    .item {
      &:not([data-type=silver]) {
        display: none;
      }
    }
  }

  &.show-all {
    .item {
      display: flex;
    }
  }

  @media (--laptop-down) {
    &__info {
      margin: 0 auto 40px;
      max-width: 500px;
      text-align: center;
    }
  }

  @media (--mobile) {
    .item {
      max-width: 400px;
      margin: 0 auto 28px;

      &__price {
        margin-bottom: 6px;
      }
    }
  }

  @media (--tablet-up) {
    padding: 70px 0 42px;

    &__items {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      max-width: 760px;
    }

    .item {
      margin: 0 14px 28px;
      width: calc(50% - 28px);

      &--wide {
        width: calc(100% - 28px);
      }
    }

    &__filter {
      margin-bottom: 50px;

      .selector {
        &__image {
          width: 65px;
          height: 60px;
        }
      }
    }

    &--large-margin {
      padding-bottom: 80px;
    }
  }

  @media (--desktop-up) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }

    &__info {
      max-width: 380px;
      padding: 46px 60px 0 0;
    }

    &__items {
      justify-content: center;
      flex-grow: 1;
      margin: 0 -14px;
    }

    &--wide {
      .list__items {
        max-width: none;
      }

      .item {
        width: calc(33.33% - 28px);

        &--wide {
          width: calc(66.66% - 28px);
        }
      }
    }

    &--centered-text {
      .list__inner {
        align-items: center;
      }

      .list__info {
        padding-top: 12px;
      }
    }

    &--large-margin {
      padding-bottom: 80px;
    }
  }
}

.contact {
  background: #1e1c1c;
  color: #979797;
  font-style: italic;
  padding: 40px 0 60px;

  &__title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-bottom: 8px;
  }

  .form {
    font-style: normal;
  }

  &__info {
    max-width: 256px;

    > div {
      margin-bottom: 33px;
    }

    a {
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  @media (--tablet-down) {
    &__inner {
      max-width: 730px;
      margin: 0 auto;
    }

    &__form {
      margin-bottom: 80px;
    }
  }

  @media (--mobile) {
    &__inner {
      max-width: 500px;
    }
  }

  @media (--tablet-up) {
    padding: 70px 0 90px;
  }

  @media (--laptop-up) {
    padding: 90px 0 128px;

    &__inner {
      display: flex;
      justify-content: space-between;

      > div {
        flex-grow: 1;
      }
    }

    &__form {
      margin-right: 50px;
    }
  }
}

.menu {
  background: rgb(37, 35, 33);
  background: linear-gradient(227deg, rgb(37, 35, 33), rgb(22, 21, 21));
  background: -webkit-linear-gradient(227deg, rgb(37, 35, 33), rgb(22, 21, 21));
  padding: 56px 0;
  color: #fefefe;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  transition:  opacity .3s cubic-bezier(.975,.015,.46,.99), visibility 0s .3s;
  z-index: 10;
  -webkit-overflow-scrolling: touch;

  //&::before {
  //  background: linear-gradient(227deg, rgb(37, 35, 33), rgb(22, 21, 21));
  //  background: -webkit-linear-gradient(227deg, rgb(37, 35, 33), rgb(22, 21, 21));
  //  content: '';
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //}

  .center {
    overflow: hidden;
  }

  ul {
    padding: 20px 0;

    svg {
      fill: #9c8265;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    letter-spacing: 0;
    opacity: 0;
    position: relative;
    transform: scale(1.5);
    transition: transform .3s cubic-bezier(.975,.015,.46,.99), opacity .3s cubic-bezier(.975,.015,.46,.99);
    -webkit-overflow-scrolling: touch;
  }

  &__main {
    font-size: 26px;
    font-weight: 300;
    line-height: 1.85;
  }

  &__secondary {
    color: #978d8d;
    font-size: 17px;
    line-height: 1.87;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: all .2s .7s ease;

    ul {
      padding-right: 20px;
    }

    a {
      transition: all .2s ease;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__secondary,
  &__social {
    position: relative;

    &::before {
      background: #c0a98f;
      content: '';
      width: 38px;
      height: 1px;
      position: absolute;
      top: 0;
    }
  }

  &__social {
    svg {
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 17px;
      margin: -3px 5px 0 0;
      fill: currentColor !important;
      opacity: 0.8;
    }
  }

  .large-menu li {
    opacity: 0;
    transform: translateX(50px);
    transition: .2s .3s ease;

    &:nth-child(2) {
      transition-delay: .35s;
    }

    &:nth-child(3) {
      transition-delay: .4s;
    }

    &:nth-child(4) {
      transition-delay: .45s;
    }

    &:nth-child(5) {
      transition-delay: .5s;
    }

    &:nth-child(6) {
      transition-delay: .55s;
    }

    &:nth-child(7) {
      transition-delay: .6s;
    }

    &:nth-child(8) {
      transition-delay: .65s;
    }
  }

  &.shown {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s cubic-bezier(.975,.015,.46,.99), visibility 0s 0s;

    .menu__inner {
      opacity: 1;
      transform: scale(1);
      transition: transform .3s cubic-bezier(.975,.015,.46,.99), opacity .3s cubic-bezier(.975,.015,.46,.99);
    }

    .menu__secondary {
      opacity: 1;
    }

    .menu__images {
      opacity: 1;
      transform: translateX(0);
    }

    .large-menu li {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (--mobile) {
    &__inner {
      padding: 0 22px;
    }

    &__secondary {
      ul:first-child {
        margin-right: auto;
      }
    }

    ul {
      &.menu__informational {
        padding-top:32px;
      }
    }

    &__social {
      width: 100%;
    }
  }

  @media (--tablet-up) {
    padding: 70px 50px 38px;

    &__secondary {
      ul {
        margin-right: auto;
      }
    }

    &__social {
      &::before {
        display: none;
      }
    }
  }

  @media (--tablet) {
    &__inner {
      display: flex;
      align-items: center;
    }
  }

  @media (--laptop-up) {
    padding-top: 80px;

    .center {
      min-height: 100%;
      display: flex;
      align-items: center;
    }

    &__inner {
      display: flex;
    }

    &__main {
      padding-top: 0 !important;
    }

    &__content,
    &__images {
      display: inline-block;
      width: 50%;
    }

    &__images {
      opacity: 0;
      padding-top: 20px;
      position: relative;
      transform: translateX(50px);
      transition: all .2s .7s ease;

      div {
        position: absolute;
        top: 14px;
        width: 100%;
        opacity: 0;
        transition: all .5s ease-out;

        &::after {
          content: '';
          display: block;
          padding-bottom: 60%;
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  @media (--desktop-up) {
    padding: 100px 0 12px;

    &__main {
      padding-bottom: 24px !important;
    }

    &__content {
      padding-left: 40px;
    }

    &__secondary {
      padding-top: 12px !important;
    }
  }

  @media (min-width: 1400px) {
    &__content {
      padding-left: 94px;
    }
  }
}

.load {
  position: relative;
}

.loader {
  background: rgba(221, 215, 210, .7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0s 0.3s;

  svg {
    fill: currentColor;
    transition: transform 0.4s ease;
    transform: scale(1.1);
    width: 25px;
    height: 25px;
  }

  .load & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;

    svg {
      transform: scale(1);
    }

    .minute-hand {
      transform-origin: 32px 33px;
      animation: rotate 3s linear infinite;
    }
  }

  .product__purchase-block & {
    background: none;
  }

  .cart & {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.load .load-hidden {
  visibility: hidden;
  transition: none !important
}

@keyframes rotate {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.product-summary {
  background: #fff;
  padding: 50px 0;
  position: relative;

  &__drawing {
    display: none;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
  }

  &__icon {
    background: no-repeat 50% 50% / contain;
    flex-shrink: 0;
    height: 55px;
    width: 55px;
    margin-right: 18px;
  }

  &__title {
    font-family: 'Neutra Text TF', sans-serif;
    font-weight: 500;
    letter-spacing: .06em;
    text-transform: uppercase;
  }

  &__text {
    color: #5f5f5e;
  }

  li {
    align-items: center;
    display: flex;
    margin-bottom: 40px;
    width: 100%;
  }

  @media (min-width: 600px) {
    &__inner {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 590px;
    }

    li {
      margin: 0 15px 50px;
      width: calc(50% - 30px);
    }
  }

  @media (--tablet-up) {
    padding: 90px 0 70px;
  }

  @media (--desktop-up) {
    padding: 130px 0 100px;

    &__drawing {
      background: no-repeat 50% 50% / contain;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 342px;
      height: 155px;
    }
  }
}

@font-face {
  font-family: 'Neutra Text';
  src:  url('/assets/fonts/Neutra-Text-Light-Alt.woff2') format('woff2'),
        url('/assets/fonts/Neutra-Text-Light-Alt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neutra Text';
  src:  url('/assets/fonts/Neutra-Text-Book-Alt.woff2') format('woff2'),
        url('/assets/fonts/Neutra-Text-Book-Alt.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neutra Text TF';
  src:  url('/assets/fonts/Neutra-Text-TF-Demi.woff2') format('woff2'),
        url('/assets/fonts/Neutra-Text-TF-Demi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neutra Text TF';
  src:  url('/assets/fonts/Neutra-Text-TF-Bold-Alt.woff2') format('woff2'),
        url('/assets/fonts/Neutra-Text-TF-Bold-Alt.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src:  url('/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('/assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src:  url('/assets/fonts/Montserrat-Medium.woff2') format('woff2'),
  url('/assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src:  url('/assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

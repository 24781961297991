.cart-items {
  margin: 0 0 4px 0;
  min-width: 100%;
  padding-top: 6px;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 6px 0;
  width: 100%;

  &__image {
    width: 66px;
    height: 66px;
    margin: 0 5px 0 -6px;
    position: relative;
    flex-shrink: 0;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0;
    margin-right: auto;
    padding-top: 6px;

    div {
      margin-bottom: 6px;
    }
  }

  &__remove {
    color: rgba(151, 151, 151, .8);
    font-size: 16px;
    letter-spacing: 0;
    padding-left: 12px;
    position: relative;

    &::before,
    &::after {
      background: currentColor;
      content: '';
      position: absolute;
      height: 1px;
      width: 10px;
      transform-origin: 50% 50%;
      left: 0;
      top: calc(50% - 1px);
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  &__cost {
    color: #9c8265;
    flex-shrink: 0;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0;
    margin-left: auto;
    padding-left: 10px;
  }

  &--strap {
    .cart-item__image {
      min-width: 60px;

      img {
        transform: rotate(-90deg);
      }
    }
  }
}

@media (min-width: 500px) {
  .cart-items {

  }

  .cart-item {
    padding: 12px 0;

    &__image {
      width: 106px;
      height: 106px;
      margin-left: -14px;
    }

    &--strap {
      .cart-item__image {
        min-width: 106px;
      }
    }
  }
}

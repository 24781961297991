.photo {
  background: rgba(0, 0, 0, .8);
  color: #fff;
  font: 300 24px/1.33 'Neutra Text', sans-serif;
  letter-spacing: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 136%;

  &__bg {
    position: absolute;
    top: -30px;
    bottom: -30px;
    width: 100%;

    div {
      background: no-repeat 50% 50% / cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__inner {
    display: flex;
    padding: 20px;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    height: 100%;
  }

  &__quote {
    max-width: 410px;
    margin: auto;

    @media (--mobile) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 500px) {
    padding-bottom: 100%;
  }

  @media (--tablet-up) {
    font-size: 34px;
    padding-bottom: 60%;

    &__quote {
      max-width: 470px;

      &--left {
        margin-left: 0;
      }

      &--right {
        margin-right: 0;
      }
    }
  }

  @media (--desktop-up) {
    padding-bottom: 44.5%;
  }
}

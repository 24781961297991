.story {
  position: relative;

  .line {
    background: #a98970;
  }

  &__button {
    color: #efefef;
    position: absolute;
    bottom: 185px;
    width: 100%;
    text-align: center;

    .line {
      margin: 0 auto 25px;
    }
  }

  .initial {
    &:first-letter {
      font-size: 52px;
      font-weight: 300;
      line-height: .7;
      color: #9d8467;
      float: left;
      padding: 6px 8px 0 3px;
    }
  }

  .part {
    position: relative;

    &__bg {
      max-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .line {
      margin: 0 auto;
    }

    &:not(.part--02) {
      background: #14130f;
      color: #f9f7f5;
    }

    &--04 {
      background: #0f0f10 !important;
    }

    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      position: relative;
      z-index: 1;
    }

    &__title,
    p {
      max-width: 350px;
      margin-bottom: 30px;
    }

    p {
      text-align: justify;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 36px;
      font-weight: 300;
      line-height: 1.24;
      letter-spacing: 0.16em;
      max-width: 90%;
      text-align: center;
      text-transform: capitalize;
    }

    &--01 {
      overflow: hidden;
      min-height: 100vh;
      padding: 300px 0 50px;

      .part__bg {
        padding-bottom: 350px;

        img {
          object-position: 0 0;
        }
      }
    }

    &--02 {
      padding: 50px 0 40px;

      .line {
        margin-bottom: 46px;
      }
    }

    &--03 {
      padding: 55% 0 0;
      overflow: hidden;

      .part__bg {
        padding-bottom: 50%;
      }

      &__content {
        transform: translateY(8vw);
      }

      .line {
        display: none;
      }
    }

    &--04 {
      padding-top: 45%;

      .line {
        margin: 20px 0 46px;
      }

      .part__bg {
        padding-bottom: 68%;
      }
    }
  }

  &__img {
    background: rgba(0, 0, 0, .3);
    overflow: hidden;
    margin: 20px 0 46px;
    position: relative;
    width: 100%;

    img {
      object-fit: cover;
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: calc(100% + 60px);
    }

    @media (--mobile) {
      height: 70vw;
    }

    @media (--tablet) {
      height: 50vw;
    }

    @media (--desktop-up) {
      height: 645px;
      margin: 46px 0 62px;
    }
  }

  .appearance {
    opacity: 0;
    transform: translateY(50px);
    transition: all .5s ease-out;

    &-enter {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (--tablet) {
    .part {
      &--01 {
        .part__bg {
          &::after {
            background: transparent linear-gradient( to bottom, rgba(20,19,15,0), rgba(20,19,15,1));
            bottom: 0;
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            height: 60px;
          }
        }
      }
    }
  }

  @media (--laptop-down) {
    .part {
      &--03,
      &--04 {
        .part__bg {
          &::after {
            background: transparent linear-gradient( to top, rgba(20,19,15,0), rgba(20,19,15,1));
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            height: 60px;
          }
        }
      }

      &--03 {
        .part__bg {
          &::after {
            bottom: 0;
            background: transparent linear-gradient( to bottom, rgba(20,19,15,0), rgba(20,19,15,1));
          }
        }
      }
    }

    //@media (min-width: 500px) {
    //  .part {
    //    &--01 {
    //      padding: 80px 20px 50px;
    //
    //      .part__bg {
    //        padding-bottom: 85%;
    //      }
    //
    //      .part__content {
    //        width: 50vw;
    //        margin-left: auto;
    //      }
    //    }
    //  }
    //}
  }

  @media (--tablet-up) {
    .part {

      &__title,
      p {
        max-width: 540px;
      }

      &__title {
        font-size: 42px;
        text-transform: uppercase;
      }

      &__button {
        bottom: 170px;
      }


      //&--01 {
      //  padding: 80px 40px 80px;
      //
      //  .part__bg {
      //    height: 100%;
      //    padding-bottom: 0;
      //  }
      //
      //  .line {
      //    position: absolute;
      //    left: 50%;
      //    bottom: 46px;
      //  }
      //}

      &--03 {
        padding-top: 36%;

        .part__content {
          transform: translateY(50px);
        }
      }
    }
  }

  @media (--laptop-up) {
    .part {
      &--01 {
        .part__bg {
          padding-bottom: 52%;
        }

        .part__content {
          margin-right: 100px;
        }
      }

      &--03 {
        padding-top: 35%;

        .part__content {
          transform: translateY(80px);
        }
      }
    }

    &__button {
      bottom: 21vw;
    }
  }

  @media (--desktop-up) {
    .part {

      .initial {
        &:first-letter {
          padding: 8px 5px 0 3px;
        }
      }

      &__content {
        padding: 0 20px;
      }

      &__bg {
        padding-bottom: 0 !important;
        width: 100%;
        bottom: 0;
        top: 0;
      }

      &--01 {
        padding: 160px 0 140px;

        .part__content {
          margin-right: 0;
        }

        .line {
          margin-top: 80px;
          margin-bottom: -30px;
        }
      }

      &--02 {
        padding-bottom: 60px;

        .part__content {
          margin: 0 auto;
          max-width: 1355px;
        }

        .line {
          margin-bottom: 39px;
        }
      }

      &--03 {
        min-height: 50vw;
        padding: 0;

        .part__content {
          position: absolute;
          width: 100%;
          bottom: -45px;
        }

        .part__bg {
          img {
            object-position: 50% 100%;
          }
        }

        .line {
          display: block;
          margin-top: 15px;
        }
      }

      &--04 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 85vw;
        padding-top: 24%;

        .part__bg {
          padding-bottom: 68% !important;
          bottom: auto;
        }

        .line {
          margin: 26px auto 40px;
        }
      }
    }

    &__button {
      bottom: 24.5vw;
    }
  }

  @media (--hd) {
    .part {
      &--01 {
        min-height: 800px;
        padding: 100px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .part__content {
          margin: 0 0 50px;
        }
      }
    }
  }
}

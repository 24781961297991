.design {
  padding: 45px 0;
  position: relative;

  &__bg1 {
    position: absolute;
    background: url('../images/drawing-01.png') no-repeat 0 50% / contain;
    width: 420px;
    height: 565px;
    top: -390px;
    z-index: -1;
  }

  &__bg2 {
    position: absolute;
    background: url('../images/drawing-02.png') no-repeat 100% 50% / contain;
    width: 412px;
    height: 606px;
    right: 0;
    bottom: -184px;
  }

  &__items {
    display: flex;
    margin: 0 -7px;
  }

  &__subtitle {
    font-family: 'Neutra Text TF', sans-serif;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.1em;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .item {
    flex-direction: column;
    margin: 0 7px;
    display: flex;

    p {
      margin-bottom: 28px;
      opacity: .8;
    }

    a {
      position: relative;

      &::after {
        background: currentColor;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
      }
    }

    &__image {
      background: #dadada;
      position: relative;
      flex-grow: 1;

      &::after {
        content: '';
        display: block;
        padding-bottom: 101%;
      }

      img {
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      line-height: 1.25;
      margin-bottom: 20px;
    }

    &--text {
      justify-content: center;
      padding-right: 16px;

      > div {
        max-width: 500px;
      }
    }

    &--secondary {
      .item__image {
        &::after {
          padding-bottom: 72%;
        }
      }
    }

    &--wide {
      .item__image {
        &::after {
          padding-bottom: 65%;
        }
      }
    }

    @media (--tablet-down) {
      &--secondary {
        display: none;
      }
    }
  }

  &:last-child {
    padding-bottom: 100px;
  }

  &--no-padding {
    padding-bottom: 0;
  }

  @media (--mobile) {
    &__items {
      flex-direction: column;
    }

    .item {
      &--text {
        padding: 0 10px;
      }

      .title {
        margin-bottom: 6px;
      }

      &__image {
        &::after {
          padding-bottom: 78%;
        }
      }
    }

    .show-mobile {
      padding-top: 34px;
      text-align: center;
    }
  }

  @media (--tablet-down) {
    .item {
      &--text {
        text-align: center;
        padding: 0;
        margin: 0 auto;
      }

      &__image {
        margin-bottom: 40px;
      }
    }
  }

  @media (--laptop-up) {
    padding: 80px 0;

    .item {
      width: 50%;

      &__image {
        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }

      &--wide {
        width: 66.66%;
      }

      &--text {
        padding: 0 18px;

        > div {
          max-width: 346px;
        }
      }
    }

    &:last-child {
      padding-bottom: 120px;
    }

    &--small-padding {
      padding: 60px 0 40px;
    }

    &--inverted {
      .item--text {
        order: 2;
      }
    }

    &--no-padding {
      padding-bottom: 0;
    }
  }

  @media (--desktop-up) {
    padding: 144px 0 140px;

    .item {
      max-width: 485px;

      &--text {
        max-width: none;
        padding: 30px 16px 0 0;

        > div {
          margin: 0 auto;
        }
      }

      &--secondary {
        max-width: 330px;
      }

      &--wide {
        max-width: 750px;
      }
    }

    &:last-child {
      padding-bottom: 205px;
    }

    &--small-padding {
      padding: 94px 0 50px;
    }

    &--no-padding {
      padding-bottom: 0;
    }
  }
}

.faq-block {
  padding: 40px 0;

  .item {
    border-bottom: 1px solid #c3c3c3;
    padding: 3px 0;

    &:first-child {
      border-top: 1px solid #c3c3c3;
    }

    &__head {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      font-weight: 300;
      letter-spacing: 0;
      padding: 15px 0;

      i {
        background: currentColor;
        border-radius: 50%;
        flex-shrink: 0;
        height: 20px;
        width: 20px;
        position: relative;

        &::before,
        &::after {
          background: #ffffff;
          content: '';
          display: block;
          position: absolute;
          left: calc(50% - 4px);
          top: 50%;
          width: 8px;
          height: 1px;
          transform-origin: 50% 50%;
        }

        &::after {
          transform: rotate(90deg);
          transition: opacity .3s ease;
        }
      }
    }

    &__question {
      font: inherit;
    }

    &__body {
      max-height: 0;
      visibility: hidden;
      transition: max-height .3s ease, visibility 0s .3s;
      //padding-left: 34px;

      > div {
        opacity: 0;
        transition: all .2s ease;
      }

      p,
      ul,
      ol {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      h4 {
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-bottom: 2px;
      }

      ul {
        color: #606060;
        padding-left: 17px;
      }

      li {
        padding-left: 18px;
        position: relative;

        &::before {
          background: currentColor;
          content: '';
          position: absolute;
          width: 3px;
          height: 3px;
          left: 0;
          top: calc(50% - 3px);
        }
      }
    }

    &__body-inner {
      padding: 3px 20px 30px 0;
    }

    &.shown {
      .item__head i::after {
        opacity: 0;
      }

      .item__body {
        max-height: 500px;
        visibility: visible;
        transition: max-height .3s ease, visibility 0s 0s;

        > div {
          opacity: 1;
          transition: all .2s .1s ease;
        }
      }
    }
  }

  @media (--tablet-up) {
    padding: 60px 0 40px;
  }

  @media (--desktop-up) {
    padding: 92px 0 58px;
  }
}

@media (--laptop-up) {
  .item__title.mobile {
    display: none;
  }
}
@media (--tablet-down) {
  .item__title.desktop {
    display: none;
  }
}

.features-bar {
  background: #fff;
  padding: 12px 0 10px;
  position: relative;

  .item {
    &__inner {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &__icon {
      background: url('/assets/images/icons.png') no-repeat 0 50% / auto 100%;
      flex-shrink: 0;
      margin-right: 6px;
      height: 22px;
      width: 22px;
    }

    &__title {
      font-size: 12px;
      letter-spacing: 0.1em;
      padding-top: 3px;
      text-transform: uppercase;
    }

    &--secure {
      .item__icon {
        background-position-x: 0;
      }
    }

    &--shipping {
      .item__icon {
        background-position-x: -88px;
      }
    }


    &--warranty {
      .item__icon {
        background-position-x: -22px;
      }
    }


    &--express {
      .item__icon {
        background-position-x: -66px;
      }
    }


    &--returns {
      .item__icon {
        background-position-x: -44px;
      }
    }
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1165px;
    margin: 0 auto;
  }

  &--type2 {
    padding: 74px 0 10px;
  }

  @media (--mobile) {
    &--product {
      border-bottom: 1px solid #ededed;
      border-top: 1px solid #ededed;
    }
  }

  @media (--tablet-down) {
    &__items {
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    .item {
      margin: 10px;
      width: calc(50% - 20px);
    }
  }

  @media (--tablet-up) {
    padding: 20px 0;

    .item {
      &__icon {
        margin-right: 10px;
        height: 30px;
        width: 30px;
      }

      &__title {
        font-size: 13px;
      }

      &--secure {
        .item__icon {
          background-position-x: 0;
        }
      }

      &--shipping {
        .item__icon {
          background-position-x: -120px;
        }
      }

      &--warranty {
        .item__icon {
          background-position-x: -30px;
        }
      }

      &--express {
        .item__icon {
          background-position-x: -90px;
        }
      }

      &--returns {
        .item__icon {
          background-position-x: -60px;
        }
      }
    }

    &--type2 {
      padding: 82px 0 10px;

      .features-bar__items {
        justify-content: center;
      }
    }
  }

  @media (--desktop-up) {
    .item {
      margin-right: 40px;
      white-space: nowrap;
    }

    &--type2 {
      .features-bar__items {
        justify-content: center;
      }

      .item {
        margin: 0 40px;
      }
    }
  }
}

.reviews {
  background: #fff;
  padding: 10px 0 40px;

  &__content {
    max-width: 726px;
    margin: 0 auto;
  }

  .item {
    border-bottom: 1px solid #ececec;
    padding: 30px 0;

    &__info {

    }

    &__content {

    }

    &__stars {

    }

    &__name {
      text-transform: uppercase;
    }

    &__date {
      font-style: italic;
      font-size: 12px;
      font-weight: 300;
      opacity: .8;
    }

    &__title {
      font-size: 34px;
      text-transform: uppercase;
      letter-spacing: .1em;
    }

    &__text {
      opacity: .8;
    }
  }

  &__pagination {
    margin-top: 30px;
  }

  @media (--mobile) {
    .item {
      &__name,
      &__date {
        display: inline-block;
      }
    }
  }

  @media (--tablet-up) {
    padding: 20px 0 90px;

    .item {
      display: flex;
      padding: 38px 0 42px;

      &__info {
        flex-shrink: 0;
        margin-right: 15px;
        padding-top: 12px;
        width: 160px;
      }

      &__content {
        flex-grow: 1;
      }

      &__name {
        margin: 25px 0 2px;
      }

      &__title {
        margin-bottom: 13px;
      }
    }
  }
}

.payment-form {
  padding: 10px 20px;
  margin-bottom: 16px;

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  input {
    background-color: #fff;
  }

  .label {
    margin-bottom: 6px;
  }

  .field {
    margin-bottom: 12px !important;
  }

  &__note {
    max-width: 205px;
    flex-shrink: 0;
    letter-spacing: 0;
    //padding: 38px 0px 8px 25px;

    p {
      color: #afafaf;
      font-style: italic;
      letter-spacing: 0;
      line-height: 1.3;
    }

    .security-icon {
      background: no-repeat 50% 50% / contain;
      display: inline-block;
      width: 15px;
      height: 20px;
      margin: 0 13px -4px 0;
    }
  }

  .norton-logo {
    background: no-repeat 50% 50% / contain;
    width: 106px;
    height: 46px;
  }

  @media (--mobile) {
    &__note {
      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  @media (--tablet-up) {
    display: flex;

    &__note {
      padding: 38px 0px 8px 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

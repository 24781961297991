.favorite {
  background: #000;
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;

  &__bg {
    position: absolute;
    top: -20px;
    bottom: -20px;
    width: 100%;

    div {
      background: no-repeat 50% 50% / cover;
      width: 100%;
      height: 100%;
    }

    @media (--tablet-up) {
      left: 0;
    }
  }

  &__content {
    color: #fff;
    display: flex;
    min-height: 136vw;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 0;
    position: relative;

    @media (min-width: 500px) {
      min-height: 100vw;
    }

    @media (--tablet-up) {
      margin-left: auto;
      min-height: 44vw;
      max-width: 50%;
      padding: 80px 0;

      .favorite--text-left & {
        margin-left: 30px;
      }
    }

    @media (--desktop-up) {
      .favorite--text-left & {
        margin-left: 90px;
      }
    }
  }

  .title,
  .subtitle,
  p {
    max-width: 290px;
  }

  p {
    margin-bottom: 38px;
  }
}

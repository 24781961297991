.to-top {
  color: #cab49b;
  left: calc(50% - 30px);
  bottom: 45px;
  background: #141414;
  border-radius: 50%;
  height: 60px;
  opacity: 0;
  position: fixed;
  transform: translateZ(0);
  transition: opacity .2s ease, visibility 0s .5s;
  visibility: hidden;
  width: 60px;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s ease, visibility 0s 0s;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 14px;
  }

  &::before {
    border-left: 1px solid currentColor;
    border-top: 1px solid currentColor;
    height: 17px;
    left: calc(50% - 8px);
    top: 15px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    width: 17px;
  }

  &::after {
    background: currentColor;
    height: 35px;
    left: 50%;
    width: 1px;
  }

  @media (--mobile) {
    background: #fff;
    box-shadow: 0 0 25px 5px rgba(255, 255, 255, 0.1);
    bottom: 20px;
    left: auto;
    position: fixed;
    right: auto;
    transform: translateZ(0) scale(.75);

    &::before {
      border-color: #1a1a1a;
    }

    &::after {
      background: #1a1a1a;
    }
  }

  @media (--tablet) {
    bottom: 60px;
  }
}
